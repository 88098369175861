import React, { useState, useEffect } from 'react';
import './style.css';
import OptionBox from './OptionBox';
import ClinicalTrials from './OptionTypes/ClinicalTrials';
import IntInterventionalType from './OptionTypes/IntInterventionalTypes';
import MultipleTypes from './OptionTypes/multipleTypes';
import Preferences from './OptionTypes/Preferences';
import { useTranslation } from "utils/modifiedTranslation";
import InterventionalTypeV2 from './InterventionalTypeV2';
import app from 'new-ui/app';
import { PAGES } from 'new-ui/constants';

import { 
    treatment_types,
    clinical_trial_phases,
    interventional_types,
    administration_methods
} from './options';

const CATEGORY = {
    CLINICAL_TRIAL_PHASES: 'clinical_trial_phases',
    TREATMENT_TYPES: 'treatment_types',
    ADMINISTRATION_METHODS: 'administration_methods',
    INTERVENTIONAL_TYPES: 'interventional_types'
};

const TreatmentOptions = ({onChange}) => {
    const isSearchPage = app.isCurrentPage(PAGES.SEARCH);
    const { t } = useTranslation();

    const [optionsArrays] = useState({
        treatment_types,
        clinical_trial_phases,
        interventional_types,
        administration_methods,
    });

    const [selectedOptions, setSelectedOptions] = useState({
        [CATEGORY.CLINICAL_TRIAL_PHASES]: [],
        [CATEGORY.TREATMENT_TYPES]: [],
        [CATEGORY.ADMINISTRATION_METHODS]: [],
        [CATEGORY.INTERVENTIONAL_TYPES]: [],
    });
    const [openedCategory, setOpenedCategory] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        };
        const currentParams = new URLSearchParams(window.location.search);
        Object.values(CATEGORY).forEach(category => {
            currentParams.delete(category);
        });
        Object.entries(selectedOptions).forEach(([category, values]) => {
            values.forEach(value => currentParams.append(category, value));
        });
        const url = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState({}, '', url);
        // eslint-disable-next-line
    }, [selectedOptions]);


    function resetTreatmentTypes(queryParams, newValue) {
        const treatmentTypes = queryParams.getAll('treatment_types');
        if(treatmentTypes.length === 1) {
            return
        }
        queryParams.delete('treatment_types');
        queryParams.append('treatment_types', newValue);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, '', newUrl);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const initialOptions = {
            [CATEGORY.CLINICAL_TRIAL_PHASES]: [],
            [CATEGORY.TREATMENT_TYPES]: [],
            [CATEGORY.ADMINISTRATION_METHODS]: [],
            [CATEGORY.INTERVENTIONAL_TYPES]: [],
        };

        // const hasInterventionalTypes = queryParams.has(CATEGORY.INTERVENTIONAL_TYPES);
        // if (!hasInterventionalTypes) {
        //     initialOptions[CATEGORY.INTERVENTIONAL_TYPES] = [...interventional_types.map(el => el.value)];
        // }

        const hasTreatmentTypes = queryParams.has(CATEGORY.TREATMENT_TYPES);
        if (!hasTreatmentTypes) {
            if(!isSearchPage) {
                // initialOptions[CATEGORY.TREATMENT_TYPES] = [...treatment_types.map(el => el.value)];
                initialOptions[CATEGORY.TREATMENT_TYPES] = [];
            }else {
                initialOptions[CATEGORY.TREATMENT_TYPES] = ['clinical_trials'];
            }
        }else if(hasTreatmentTypes && isSearchPage) {
            resetTreatmentTypes(queryParams, CATEGORY.TREATMENT_TYPES)
        }

        // const hasAdministrationMethods = queryParams.has(CATEGORY.ADMINISTRATION_METHODS);
        // if (!hasAdministrationMethods) {
        //     initialOptions[CATEGORY.ADMINISTRATION_METHODS] = [...administration_methods.map(el => el.value)];
        // }
        // const hasClinicalTrialPhses = queryParams.has(CATEGORY.CLINICAL_TRIAL_PHASES);
        // if (!hasClinicalTrialPhses) {
        //     initialOptions[CATEGORY.CLINICAL_TRIAL_PHASES] = [...clinical_trial_phases.map(el => el.value)];
        // }

        queryParams.forEach((value, key) => {
            if (Object.values(CATEGORY).includes(key)) {
                const selectedOptionsArray = optionsArrays[key];
                const validValues = selectedOptionsArray.filter(el => el.value === value).map(el=> el.value);
                initialOptions[key].push(...validValues)
            }
        });

        setSelectedOptions(initialOptions);
        setIsInitialLoad(false);
        if (onChange) {
            onChange(initialOptions);
        }

        // Check for the 'open' query parameter
        const openCategory = queryParams.get('open');
        if (openCategory && Object.values(CATEGORY).includes(openCategory)) {
            setOpenedCategory(openCategory);
        }
        // eslint-disable-next-line
    }, []);


    const handleSearchOptionChange = (category, value) => {
        setSelectedOptions(prev => {
            const newOptions = {
                ...prev,
                [category]: [value],
            };
    
            if (onChange) {
                onChange(newOptions);
            }
    
            return newOptions;
        });
    };    

    const handleOptionChange = (category, value, isChecked) => {
        setSelectedOptions(prev => {
            const updatedCategory = isChecked
                ? [...prev[category], value]
                : prev[category].filter(item => item !== value);
    
            const newOptions = {
                ...prev,
                [category]: updatedCategory,
            };
    
            if (onChange) {
                onChange(newOptions);
            }
    
            return newOptions;
        });
    };    

    const handleOpen = (category) => {
        setOpenedCategory(category);
    };

    const shouldOpenCategory = (category) => {
        return category === openedCategory;
    };    

    const wrapperClassName = `treatment-options-wrapper ${openedCategory ? openedCategory : ''} ${isSearchPage ? 'hcp-platform':'patient-platform'}`;
    return (
        <div className={wrapperClassName}>
            {/* <div className='treatment-options-header'>
                <h3 className='options-title'>{t("treatment_options.title")}</h3>
            </div> */}
            <div className='treatment-options-mobile'>
                {isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <IntInterventionalType
                            category={CATEGORY.INTERVENTIONAL_TYPES}
                            selectedOptions={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}
                            onOptionChange={handleOptionChange}
                            options={interventional_types}
                        />
                    }
                />}
                {isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                    title={t("treatment_options.option.title.preferences")}
                    withTitle={false}
                    selectedCount={[ 
                        // selectedOptions[CATEGORY.TREATMENT_TYPES].length +
                        selectedOptions[CATEGORY.ADMINISTRATION_METHODS].length +
                        selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES].length
                    ]}
                    contentComponent={
                        <Preferences
                            // category_1={CATEGORY.TREATMENT_TYPES}
                            category_2={CATEGORY.ADMINISTRATION_METHODS}
                            category_3={CATEGORY.CLINICAL_TRIAL_PHASES}
                            // options_1={treatment_types}
                            options_2={administration_methods}
                            options_3={clinical_trial_phases}
                            selectedOptions_1={selectedOptions[CATEGORY.TREATMENT_TYPES]}
                            selectedOptions_2={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}
                            selectedOptions_3={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}
                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                            onCancel={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                        />
                    }
                />}
                <div className={`clinical-trials-wrapper ${!isSearchPage ? 'patient-platform' : 'hcp-platform'}`}>
                    <ClinicalTrials
                        withRadio={!!isSearchPage}
                        category={CATEGORY.TREATMENT_TYPES}
                        selectedOptions={selectedOptions[CATEGORY.TREATMENT_TYPES]}
                        onOptionChange={
                            !isSearchPage ? handleOptionChange : handleSearchOptionChange
                        }
                        options={treatment_types}
                    />
                </div>
                {!isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    withTitle={false}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <MultipleTypes
                            category_1={CATEGORY.INTERVENTIONAL_TYPES}
                            options_1={interventional_types}
                            selectedOptions_1={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}

                            category_2={CATEGORY.ADMINISTRATION_METHODS}
                            options_2={administration_methods}
                            selectedOptions_2={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}

                            category_3={CATEGORY.CLINICAL_TRIAL_PHASES}
                            options_3={clinical_trial_phases}
                            selectedOptions_3={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}
                            
                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                            onCancel={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                        />
                    }
                    previewComponent={
                        <InterventionalTypeV2
                            selectedOptions={selectedOptions}
                            handleOptionChange={handleOptionChange}
                            interventional_types={interventional_types}
                            CATEGORY={CATEGORY}
                            isMobile={true}
                        />
                    } 
                />}
            </div>
            <div className='treatment-options-desktop'>
                <div className={`clinical-trials-wrapper ${!isSearchPage ? 'patient-platform' : 'hcp-platform'}`}>
                    <ClinicalTrials
                        withRadio={!!isSearchPage}
                        category={CATEGORY.TREATMENT_TYPES}
                        selectedOptions={selectedOptions[CATEGORY.TREATMENT_TYPES]}
                        onOptionChange={
                            !isSearchPage ? handleOptionChange : handleSearchOptionChange
                        }
                        options={treatment_types}
                    />
                </div>
                {!isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    withTitle={false}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <MultipleTypes
                            category_1={CATEGORY.INTERVENTIONAL_TYPES}
                            options_1={interventional_types}
                            selectedOptions_1={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}

                            category_2={CATEGORY.ADMINISTRATION_METHODS}
                            options_2={administration_methods}
                            selectedOptions_2={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}

                            category_3={CATEGORY.CLINICAL_TRIAL_PHASES}
                            options_3={clinical_trial_phases}
                            selectedOptions_3={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}

                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                            onCancel={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                        />
                    }
                    previewComponent={
                        <InterventionalTypeV2
                            selectedOptions={selectedOptions}
                            handleOptionChange={handleOptionChange}
                            interventional_types={interventional_types}
                            CATEGORY={CATEGORY}
                        />
                    } 
                />}
                {isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <IntInterventionalType
                            category={CATEGORY.INTERVENTIONAL_TYPES}
                            selectedOptions={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}
                            onOptionChange={handleOptionChange}
                            options={interventional_types}
                        />
                    }
                />}
                {isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.ADMINISTRATION_METHODS)}
                    title={t("treatment_options.option.title.administration_method")}
                    selectedCount={selectedOptions[CATEGORY.ADMINISTRATION_METHODS].length}
                    contentComponent={
                        <ClinicalTrials
                            category={CATEGORY.ADMINISTRATION_METHODS}
                            selectedOptions={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}
                            onOptionChange={handleOptionChange}
                            options={administration_methods}
                        />
                    }
                />}
                {isSearchPage && <OptionBox
                    onOpen={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                    title={t("treatment_options.option.title.clinical_trial_phase")}
                    selectedCount={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES].length}
                    contentComponent={
                        <ClinicalTrials
                            category={CATEGORY.CLINICAL_TRIAL_PHASES}
                            selectedOptions={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}
                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                        />
                    }
                />}
            </div>
        </div>
    );
};

export default TreatmentOptions;