import React from 'react';
import './style.css';
import { EVENT_TYPES } from '../../options';
import TreatmentFeedback from './TreatmentFeedback';
import StatusButton from './StatusButton';
import { useTranslation } from 'utils/modifiedTranslation';

const ExpiredEvent = ({ item, onClickStatusBtn }) => {
  const { t } = useTranslation();
  const { type, date, cycle } = item;

  const handleFeedback = (id, emoji) => {
    console.log('Feedback ',{ id, emoji });
  };

  return (
    <div className='expired-event'>
      <div className='details'>
        <span className='bullet' style={{ backgroundColor: EVENT_TYPES[type].color }}></span>
        <span className='date'>{date}</span>
        <span className='label' style={{ backgroundColor: EVENT_TYPES[type].color }}>{t(EVENT_TYPES[type].label)}</span>
        <span className='cycle'>{cycle}</span>
      </div>

      <div className='actions'>
          {type === 'treatment' && <TreatmentFeedback treatment={item} onSelect={handleFeedback} />}
          {type === 'clinic_visit' && <StatusButton onClick={onClickStatusBtn} title={t('events_widget.button.status')} />}
      </div>
    </div>
  );
};

export default ExpiredEvent;