import React, { useState } from 'react';
import './MissingCritical.css';

import app from 'new-ui/app';

// import rightArrowIcon from 'new-ui/assets/icons/right.svg';

import PropTypes from 'prop-types';

import { useTranslation } from 'utils/modifiedTranslation';

import { PAGES, POPUP_TYPES, ROUTES } from 'new-ui/constants';
import { getItemType } from 'new-ui/functions';
import { HCP_STORAGE_KEY } from 'new-ui/Search';

import { filterQuestionsByCondition } from 'new-ui/functions';
import { useEffect } from 'react';

window._items = [];

const MissingCriticalQuestions = (props) => {
  const {
    item,
    data = {},
  } = props;
  const { t } = useTranslation();
  const isHcp = app.isCurrentPage(PAGES.SEARCH);

  window._items.push(item);
  
  // let hasMissingCritical = item?.missed_questions?.length ? true : false;
  const [hasMissingCritical, setMissingCritical] = useState(null);
  let questions = item?.missed_questions || [];

  const conditionChecker = async (questions) => {
    let filteredQuestions = await filterQuestionsByCondition({ questionsIds: questions, user: app?.user });
    let missing = filteredQuestions.length ? true : false;
    setMissingCritical(missing);
  };
    
  useEffect(() => {
    conditionChecker(questions);
  },[])

  if (!data.missingCriticalFlags) {
    data.missingCriticalFlags = {
      warning: false,
      good: false,
    };
  }

  data.missingCriticalFlags[hasMissingCritical ? 'warning' : 'good'] = true;

  const onMissingQuestionClick = () => {
    if (!hasMissingCritical) {
      if (item.isSponsored) {
        app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
        return;
      }
      app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR, { type: getItemType(item) });
      return;
    }
    app.setPopup(POPUP_TYPES.MISSING_CRITICAL_INFORMATION, {
      trial: item,
    });
  };

  const consultWithUs = ()=>{
    app.setPopup(POPUP_TYPES.CONSULT_WITH_US, { user: app.user });
  }

  const onEligibilityClick = ()=>{
    if(!hasMissingCritical){
      if(app.user){
        consultWithUs();
      }else{
        app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
          theme: 'middle',
          onSignup: (user)=>{
            app.user = user;
            consultWithUs();
          },
        });
      }
      return;
    }
    const isTreatment = item.trialType === 'drug';
    localStorage.setItem(HCP_STORAGE_KEY, JSON.stringify({
      item,
      user: window?.__intakeUser,
    }));
    app.history.push(`${ROUTES[!isTreatment ? PAGES.PUBLIC_TRIAL : PAGES.PUBLIC_TREATMENT]}/${item.nct_number}?e=1`);
  }

  console.log(isHcp, item);

  if(isHcp){
    return <div 
      className={`missing-questions missing-questions-ready`}
      onClick={onEligibilityClick}
    >
      <div className="missing-questions-text-subtitle">
          {t(`hcp.${hasMissingCritical ? 'item_missed_questions' : 'item_no_missed_questions'}`)}
        </div>
    </div>
  }

  return (
    <div
      role="none"
      title={t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${!item.isSponsored ? 'speak_with_us' : 'speak_with_your_oncologist'}`}`)}
      className={`missing-questions missing-questions-${hasMissingCritical ? 'pending' : 'ready'}`}
      onClick={onMissingQuestionClick}
    >
      <div className="missing-questions-text">
        {/* <div className="missing-questions-text-title">{t(`trial.${hasMissingCritical ? 'pending' : 'ready'}`)}</div> */}
        <div className="missing-questions-text-subtitle">
          {t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${!item.isSponsored ? 'speak_with_us' : 'speak_with_your_oncologist'}`}`)}
        </div>
      </div>
      {/* <div className="missing-questions-arrow">
        <img alt="right arrow" src={rightArrowIcon} />
      </div> */}
    </div>
  );
};

MissingCriticalQuestions.propTypes = {
  item: PropTypes.object,
  data: PropTypes.object,
};

export default MissingCriticalQuestions;
