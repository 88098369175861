import React from 'react';
import './style.css';
import { ReactComponent as ClockIcon } from 'new-ui/assets/icons/watch.svg';
import { ReactComponent as WalletIcon } from 'new-ui/assets/icons/wallet.svg';
import { ReactComponent as CapsuleIcon } from 'new-ui/assets/icons/capsule.svg';
import { ReactComponent as BellIcon } from 'new-ui/assets/icons/bell.svg';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import { FEED_GA } from '../../index';

const ActionListV2 = ({onClickOption}) => {
    const { t } = useTranslation();
    return (
        <ul className='actions-list-v2'>
            <li className='actions-item' onClick={() => {
                    onClickOption({key: 1, value: 'experienceWidgetV2'})
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_still_taking_clicked);
                }}>
                <ClockIcon />
                <span className='label'>{t('treatment_widget.options.yes')}</span>
            </li>
            <li className='actions-item' onClick={() => {
                    app.setPopup(POPUP_TYPES.AI_ASSISTANT, {theme:'middle'});
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_finished_looking_clicked);
                }}>
                <WalletIcon />
                <span className='label'>{t('treatment_widget.options.looking_for_treatment')}</span>
            </li>
            <li className='actions-item' onClick={() => {
                    onClickOption({key: 3, value: 'received_addiotnal_treatment'});
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_finished_received_clicked);
                }}>
                <CapsuleIcon />
                <span className='label'>{t('treatment_widget.options.additional_treatment')}</span>
            </li>
            <li className='actions-item' onClick={() => {
                    onClickOption({key: 4, value: 'done_for_now'});
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_disease_free_clicked);
                }}>
                <BellIcon />
                <span className='label'>{t('treatment_widget.options.done_for_now')}</span>
            </li>
        </ul>
    );
};

export default ActionListV2;