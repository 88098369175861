import React, { useState } from 'react';
import './style.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { PAGES, URI } from 'new-ui/constants';
import WidgetBtn from '../Button';
import Button from 'new-ui/Components/Button';
import AssistanceButton from '../AssistanceButton';
import Chemo from 'new-ui/assets/icons/treatments/chemo.svg';
import Targeted from 'new-ui/assets/icons/treatments/targeted.svg';
import Immuno from 'new-ui/assets/icons/treatments/immuno.svg';
import Radiation from 'new-ui/assets/icons/treatments/radiation.svg';
import Combo from 'new-ui/assets/icons/treatments/combo.svg';
import OptionCheckbox from 'new-ui/TreatmentOptions/Checkbox';

const options = [
  { 
    id: 1, 
    value: 'chemotherapy', 
    label: 'Chemo', 
    color: '#E2DAFF',
    icon: Chemo
  },
  { 
    id: 2, 
    value: 'targeted_therapy', 
    label: 'Targeted', 
    color: '#FBDBE0',
    icon: Targeted
  },
  { 
    id: 3, 
    value: 'immunotherapy', 
    label: 'Immuno', 
    color: '#FDF6E2',
    icon: Immuno
  },
  { 
    id: 4, 
    value: 'radiation', 
    label: 'Radiation', 
    color: '#F5FAEA',
    icon: Radiation
  },
  { 
    id: 5, 
    value: 'combo', 
    label: 'Combo', 
    color: '#D5F3F0',
    icon: Combo
  }
];

const PreferencesWidget = ({ treatments, trials }) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const onBtnClick = () => {
  //     app.history.push(`${URI[PAGES.RESULTS]}?tab=${'TRIALS'}`);
  // };

  const handleCheckboxChange = (value) => {
      setSelectedOptions((prevSelectedOptions) => {
          if (prevSelectedOptions.includes(value)) {
              return prevSelectedOptions.filter(option => option !== value);
          } else {
              return [...prevSelectedOptions, value];
          }
      });
  };

  const logSelectedOptions = () => {
    const baseUrl = `${URI[PAGES.RESULTS]}?page=1`;
    const openParam = 'open=interventional_types';
    const queryParams = selectedOptions.length > 0 
        ? selectedOptions.map(option => `interventional_types=${option}`) 
        : [];
    const fullUrl = [baseUrl, openParam, ...queryParams].filter(Boolean).join('&');
    app.history.push(fullUrl);
  };

  return (
      <div className="preference-widget">
          <div className="options-header">
              <div className="options-count">
                  <div className="count">{treatments?.length + trials?.length}</div>
                  <span className="divider">/</span>
                  <div className="label">{t('preferences_widget.sub_title')}</div>
              </div>
          </div>
          <div className="options-body">
              <h2 className="title">{t('preferences_widget.title')}</h2>
              <h3 className="sub-title">
                  {t('preferences_widget.description')}
              </h3>

              <ul className='options-list'>
                  {options.map((option) => (
                      <li key={option.value} className='options-item'>
                          <div className='option-icon' style={{ backgroundColor: option.color }}>
                              <img src={option.icon} alt='icon' />
                          </div>
                          <OptionCheckbox
                              label={option.label}
                              checked={selectedOptions.includes(option.value)}
                              onChange={() => handleCheckboxChange(option.value)}
                          />
                      </li>
                  ))}
              </ul>
          </div>
          <div className="options-footer">
              <Button
                backgroundColor="#FFF"
                color="#213332"
                width="180px"
                className="btn btn-more-options"
                title={t('preferences_widget.actions.more_options.button')}
                action={logSelectedOptions}
              />
              <WidgetBtn title={t('preferences_widget.actions.my_options.button')} onClick={logSelectedOptions} />
              <AssistanceButton title={t('preferences_widget.actions.assistance.button')} onClick={() => {}} />
          </div>
      </div>
  );
};
export default PreferencesWidget;
