import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../Pagination';
import './style.css';
import BaseItem from '../PaginatedItems/BaseItem';
import { 
  filterByTreatmentType, 
  filterByAdministrationMethod,
  filterByClinicalTrialPhase,
  filterByInterventionalType
} from '../PaginatedItems/functions';
import ArrowIcon from 'new-ui/assets/icons/arrow-circle-black.svg';
// import InfoNote from 'new-ui/Results/ResultList/InfoNote/InfoNote';

const ITEMS_PER_PAGE = 10;

function arrangeByTreatmentCategory(data) {
  const arranged = {
      sponsored: [],
      notSponsored: {},
      unknown: []
  };

  data.forEach(item => {
      const treatmentCategory = item.treatment_category_key || 'Unknown';

      if (item.isSponsored) {
          arranged.sponsored.push(item);
      } else if (treatmentCategory === 'Unknown') {
          arranged.unknown.push(item);
      } else {
          if (!arranged.notSponsored[treatmentCategory]) {
              arranged.notSponsored[treatmentCategory] = [];
          }
          arranged.notSponsored[treatmentCategory].push(item);
      }
  });

  return arranged;
};


const PaginatedAccordion = ({items, filter}) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState('');
  const [expanded, setExpanded] = useState({});

  const toggleSection = (title) => {
    setExpanded((prev) => ({ ...prev, [title]: !prev[title] }));
  };
  
  function updatePageParam(value) {
    const url = new URL(window.location.href);
    url.searchParams.set('page', value);
    window.history.replaceState({}, '', url);
  }

  useEffect(() => {
    setCurrentPage(1)
    updatePageParam(1);
    const { treatment_types } = filter;
    if(Array.isArray(treatment_types)) {
      setSelectedType(treatment_types[0] || 'clinical_trials');
    }
  }, [filter]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get('page')) || 1;
  
  const [currentPage, setCurrentPage] = useState(initialPage);

  const filterItems = (items, filter) => {
    const { 
      treatment_types, 
      administration_methods,
      clinical_trial_phases,
      interventional_types
    } = filter;

    let filteredItems = filterByTreatmentType(items, treatment_types);
    filteredItems = filterByAdministrationMethod(filteredItems, administration_methods);
    filteredItems = filterByClinicalTrialPhase(filteredItems, clinical_trial_phases);
    filteredItems = filterByInterventionalType(filteredItems, interventional_types);
    return filteredItems;
  };

  const filteredItems = filterItems(items, filter);
  const filteredTreatments = filterByTreatmentType(filteredItems, ['standard_of_care']);
  const filteredTrials = filterByTreatmentType(filteredItems, ['clinical_trials']);

  const arranged = arrangeByTreatmentCategory(filteredTreatments);
  const selectedTrials = filteredTrials.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const treatments = filterByTreatmentType(items, ['standard_of_care']);
  useEffect(()=> {
    const $wrapper = document.querySelector('.result-list');
    if(treatments?.length > 0) {
      $wrapper.classList.remove('empty-soc');
    }else {
      $wrapper.classList.add('empty-soc');
    }
  },[treatments])

  const handlePageChange = (page) => {
    setCurrentPage(page);
    document.getElementById("root").scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <div className='paginated-list'>
      {
        selectedType === 'clinical_trials' ? (
          <>
            {selectedTrials.map((item, index) => (
              <BaseItem item={item} key={index} />
            ))}
            <Pagination
              totalItems={filteredTrials.length} 
              perPage={ITEMS_PER_PAGE} 
              onPageChange={(page) => {
                handlePageChange(page);
            }}/>
          </>
        ) : (
          <>
            {[...arranged.sponsored, ...arranged.unknown].map((item, index) => (
              <BaseItem item={item} key={index} />
            ))}
      
            {(Object.entries(arranged.notSponsored).length > 0) && (
              <div className='accordion-wrapper'>
                {Object.entries(arranged.notSponsored).map(([title, items]) => (
                  <div key={title} className={`accordion ${expanded[title] ? 'expanded' : 'collapsed'}`}>
                    <h3 className='accordion-title' onClick={() => toggleSection(title)} style={{ cursor: 'pointer' }}>
                      <span>{t(`match.list.treatment.category.title.${title}`)}</span>
                      <span className="arrow"><img src={ArrowIcon} alt="arrow" /></span>
                    </h3>
                    {expanded[title] && (
                      <div className='accordion-content'>
                        {items.map((item, index) => (
                          <BaseItem item={item} key={index} />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )
      }
    </div>
  );
};

export default PaginatedAccordion;