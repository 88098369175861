export const SurveyTypes = {
  treatmentExperience: 'treatmentExperience',
  nextScan: 'nextScan',
  nextAppointment: 'nextAppointment',
  treatment: 'treatment',
  scan: 'scan',
  clinic_visit: 'clinicVisit',
};

export const SurveyAnswerKeys = {
  aggression: 'aggression',
  sideEffects: 'side_effects',
  sideEffectsFreeText: 'side_effects_free_text',
  share: 'share',
  nextAppointment: 'nextAppointment',
  nextScan: 'nextScan',
  cycle: 'cycle',
  repeat: 'repeat',
};

export const SurveyAppointmentRepeatAnswerValues = {
  daily: 'daily',
  weekly: 'weekly',
  every_2_weeks: 'every_2_weeks',
  every_3_weeks: 'every_3_weeks',
  monthly: 'monthly',
  none: 'none',
};

export const SurveyStatus = {
  UPCOMING: 'upcoming',
  EXPIRED: 'expired',
};
