/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  AmlQuestions,
  HnsccQuestions,
  BladderQuestions,
  BrainQuestions,
  Breast2Questions,
  Crc2Questions,
  CrcQuestions,
  LungQuestions,
  MdsQuestions,
  Melanoma2Questions,
  MelanomaQuestions,
  MmQuestions,
  MpnQuestions,
  NhlQuestions,
  ProstateQuestions,
  UterineQuestions,
  Bladder2Questions,
  CllQuestions,
  GiTractQuestions,
  OvarianQuestions,
  HccQuestions,
} from 'components/Questionnaire/cancers';

import { cancerTypes } from 'utils/constants/cancerTypes';
import configs from '../../../configs';
import { conditionCheckByType } from './conditionCheckByType';
import { fetchQuestionnaireData } from '../actions';

export function conditionsChecker(form, conditions, state = {}) {
  if (!conditions || conditions.length <= 0) {
    return true;
  }

  const profile = _.isEmpty(form) ? {} : form;
  const checks = conditions.map((condition) => conditionCheckByType(profile, condition, state));
  
  return checks.every((check) => check === true);
}

export const indicationChooser = (indication) => {
  switch (indication) {
    case cancerTypes.MELANOMA:
      return MelanomaQuestions;
    case cancerTypes.MELANOMA_2:
      return Melanoma2Questions;
    case cancerTypes.CRC:
      return CrcQuestions;
    case cancerTypes.MDS:
      return MdsQuestions;
    case cancerTypes.BLADDER:
      return BladderQuestions;
    case cancerTypes.MPN:
      return MpnQuestions;
    case cancerTypes.UTERINE:
      return UterineQuestions;
    case cancerTypes.BRAIN:
      return BrainQuestions;
    case cancerTypes.MM:
      return MmQuestions;
    case cancerTypes.LUNG:
      return LungQuestions;
    case cancerTypes.BREAST_2:
      return Breast2Questions;
    case cancerTypes.NHL:
      return NhlQuestions;
    case cancerTypes.CRC_2:
      return Crc2Questions;
    case cancerTypes.PROSTATE:
      return ProstateQuestions;
    case cancerTypes.AML:
      return AmlQuestions;
    case cancerTypes.HNSCC:
      return HnsccQuestions;
    case cancerTypes.BLADDER_2:
      return Bladder2Questions;
    case cancerTypes.CLL:
      return CllQuestions;
    case cancerTypes.GI_TRACT:
      return GiTractQuestions;
    case cancerTypes.OVARIAN:
      return OvarianQuestions;
    case cancerTypes.HCC:
      return HccQuestions;
    default:
      return fetchQuestionnaireData(indication);
      // return BladderQuestions; // because it's the first in the list
  }
};

export const getBgIcon = (icon) => `${configs.publicUrl}/assets/bg-icons/${icon}`;

export const isIndicationFromBackend = (condition) => !Object.values(cancerTypes).find(((name) => name === condition));

window._indicationChooser = indicationChooser;
