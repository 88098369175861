import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import uploadWhiteIcon from 'new-ui/assets/icons/upload_white.svg';
import nextIcon from 'new-ui/assets/icons/arrow-circle-right.svg';
import fileIcon from 'new-ui/assets/icons/file.svg';
import trashIcon from 'new-ui/assets/icons/trash.svg';
import CheckIcon from 'new-ui/assets/icons/check.svg';
import { THEMES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import useForceUpdate from 'use-force-update';
import app from 'new-ui/app';
import uploadDocument from 'new-ui/assets/icons/upload_document.svg';
import Button from '../Button';
import LinkButton from '../LinkButton';

import './FilesUploaderFlatButton.css';

const STATES = {
  SELECT: 'SELECT',
  MULTI: 'MULTI',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  CUSTOM1: 'CUSTOM1',
  ANALYZING: 'ANALYZING',
  ERROR: 'ERROR',
};

window.__STATES = STATES;

const ACCEPTED_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'tif',
  'tiff',
  'pdf',
];

export const UPLOADER_STATES = STATES;

const context = {};

const checkFile = (file, fileTypes = [], fileTypesError, onFileError, maxFileSizeMb = 10) => {
  if (!file || !file.name) return;
  const extension = file.name.match(/\.[^.]+$/)?.[0];
  const ext = extension ? extension?.slice(1)?.toLowerCase() : '';
  if (file.size >= maxFileSizeMb * 1000000) {
    if (onFileError) onFileError();
    return file.error = `File size too large, please upload a file under ${maxFileSizeMb}Mb`;
  }
  if (!fileTypes.includes(ext)) file.error = fileTypesError;
  if (onFileError) onFileError();
};

const FilesUploaderFlatButton = (props) => {
  const {
    isMulti,
    header,
    footer,
    filerenderer,
    onselect = () => {},
    onstatechange = () => {},
    onfilesselected = () => {},
    fileTypes = ACCEPTED_FILE_TYPES,
    fileTypesError,
    onFileError,
    uploader = {},
    openMobileMenu,
    buttonMode = false, // use simple button instead of dragzone
    selectButton,
    isCustomButton,
    isFileCheck,
    errorComponent,
    GA,
    isUploadPopup,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = useState(STATES.SELECT);
  const [errorMesage, setErrorMessage] = useState('');
  const [files, setFiles] = useState([]);

  uploader.setState = setState;
  uploader.setFiles = setFiles;
  uploader.files = files;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  if (openMobileMenu) {
    openMobileMenu(setShowMobileMenu);
  }

  const update = useForceUpdate();

  const $dragArea = useRef();

  useEffect(() => {
    onstatechange(state);
  }, [state, onstatechange]);

  useEffect(() => {
    window.__currentState = state;
    if (state === UPLOADER_STATES.SELECT) {
      const dropzone = $dragArea.current;

      if (!dropzone) return;

      dropzone.addEventListener('dragover', (event) => { event.preventDefault(); dropzone.classList.add('dragged'); });
      dropzone.addEventListener('dragleave', (event) => { event.preventDefault(); dropzone.classList.remove('dragged'); });
      dropzone.addEventListener('drop', (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        const uploadedFiles = [];

        for (let i = 0; i < droppedFiles.length; i++) {
          const file = droppedFiles[i];
          uploadedFiles.push(file);
        }

        setFiles(uploadedFiles);
        context.onFilesSelected();
      });
    } else if (state === UPLOADER_STATES.ERROR) {
      setFiles([]);
    }
  }, [state]);

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      files.push(selectedFile);
      if (selectedFile.size >= 10 * 1000000) {
        setState(STATES.ERROR);
        setErrorMessage('Recommended File Size: 10MB');
      } else {
        onFilesSelected();
      }
    }
  };

  const photo = ($ev) => {
    $ev.stopPropagation();
    const fileInput = document.getElementById('fileInput');
    fileInput.value = '';
    fileInput.setAttribute('accept', 'image/*');
    fileInput.setAttribute('capture', 'camera');

    fileInput.click();
  };

  const cancel = () => {
    setShowMobileMenu(false);
  };

  const onFilesSelected = () => {
    onfilesselected();
    if (!isFileCheck) {
      files.forEach((f) => checkFile(f, fileTypes, fileTypesError, onFileError));
    }
    if (isMulti) setState(STATES.MULTI);
    else {
      setFiles([...files.filter((a) => !a.error)]);
      onselect();
    }
    update();
  };

  context.onFilesSelected = onFilesSelected;

  const select = () => {
    if (isMulti) setState(STATES.MULTI);
    else upload();
  };

  const isNextValid = () => files.filter((a) => !a.error).length;

  uploader.select = select;

  const upload = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.value = '';
    fileInput.removeAttribute('capture');
    if (!isFileCheck) {
      fileInput.removeAttribute('accept');
    }

    fileInput.click();
  };

  const render = () => {
    switch (state) {
      default:
      case STATES.SELECT:
        return (
          <div className="files-uploader-select-state">
            {header}
            <>
              <div className="mobile-files-uploader">
                {isCustomButton ? (
                  <Button
                    className="upload-btn"
                    backgroundColor="#C77DFF"
                    title="Upload medical files"
                    width="359px"
                    forwardIcon={uploadWhiteIcon}
                    action={() => {
                      setShowMobileMenu(true);
                    }}
                  />
                ) : (
                  <Button
                    className="upload-btn"
                    backgroundColor="#C77DFF"
                    title="Upload medical files"
                    width="359px"
                    endIcon={uploadWhiteIcon}
                    action={() => {
                      setShowMobileMenu(true);
                    }}
                  />
                )}

                { showMobileMenu ? (
                  <>
                    <div className="mobile-files-uploader-overlay">
                      <div className="mobile-files-uploader-overlay-buttons">
                        <div className="mobile-files-uploader-overlay-button" onClick={photo}>Take a photo</div>
                        <div className="mobile-files-uploader-overlay-button" onClick={upload}>Upload from device</div>
                        <div className="mobile-files-uploader-overlay-button" onClick={cancel}>Cancel</div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {
                buttonMode ? (
                  <div className="files-uploader-select-buttons">
                    <Button title="Upload File" forwardIcon={uploadWhiteIcon} action={upload} />
                    {selectButton || null}
                  </div>
                ) : (
                  <div className="flat-button" ref={$dragArea} onClick={upload}>
                    <div>
                      <img src={uploadDocument} alt="free" />
                    </div>
                    <LinkButton
                      className="link-button-text"
                      title="Upload medical files"
                      action={
                        isFileCheck ? app.sendGoogleAnalyticsEvent(GA.category, GA.events.browse, { name: 'Uplad file browse clicked' }) : null
                      }
                    />
                  </div>
                )
              }
            </>
            {footer}
          </div>
        );
      case STATES.MULTI:
        return (
          <div className="files-uploader-multi">
            {header}
            {!isUploadPopup ? (
              <div className="files-uploader-preview-num">
                {files.length}
                {' '}
                { files.length === 1 ? 'file' : 'files' }
              </div>
            ) : null}
            {files.map((file, k) => (
              <React.Fragment key={k}>
                <UploaderFile
                  file={file}
                  state={isUploadPopup && state}
                  isUploadPopup={isUploadPopup}
                  onremove={(file) => {
                    files.splice(files.indexOf(file), 1);
                    setFiles([...files]);
                  }}
                />
              </React.Fragment>
            ))}
            <div className="files-uploader-multi-buttons">
              <Button
                title="Upload More"
                action={()=>{
                  if (isFileCheck) {
                    app.sendGoogleAnalyticsEvent(GA.category, GA.events.moreUpload, { name: 'Upload more files clicked' });
                  }
                  upload();
                }}
                theme={THEMES.ORANGE}
              />
              {
                isNextValid() ? (
                  <div className="multi-button-holder">
                    <Button
                      title={t('general.next')}
                      action={() => {
                        setFiles([...files.filter((a) => !a.error)]);
                        onselect();
                      }}
                      forwardIcon={nextIcon}
                    />
                  </div>
                ) : null
              }
            </div>
          </div>
        );
      case STATES.UPLOADING:
      case STATES.ANALYZING:
        return (
          <div>
            {header}

          </div>
        );
      case STATES.SUCCESS:
        return (
          <div className="files-uploader-success">
            {header}
            {files.map((file, k) => (
              <React.Fragment key={k}>
                <UploaderFile file={file} state={state} body={filerenderer(file)} />
              </React.Fragment>
            ))}
            {footer}
          </div>
        );
      case STATES.ERROR:
        return (
          <div className="files-uploader-error">
            {header}
            {errorMesage && errorComponent ? (
              <div className="upload-page-error">
                {errorComponent()}
              </div>
            )
              : footer}
          </div>
        );
      case STATES.CUSTOM1:
        return (
          <div className="files-uploader-custom">
            {header}
          </div>
        );
    }
  };

  return (
    <div className={`files-uploader files-uploader-state-${state}`}>
      <input id="fileInput" accept={fileTypes} type="file" style={{ display: 'none' }} onChange={onFileChange} />
      {render()}
    </div>
  );
};

FilesUploaderFlatButton.propTypes = {
  isMulti: PropTypes.bool,
  header: PropTypes.any,
  footer: PropTypes.any,
  filerenderer: PropTypes.any,
  onselect: PropTypes.any,
  onstatechange: PropTypes.any,
  uploader: PropTypes.any,
  onfilesselected: PropTypes.any,
  fileTypes: PropTypes.array,
  fileTypesError: PropTypes.string,
  onFileError: PropTypes.func,
  openMobileMenu: PropTypes.func,
  buttonMode: PropTypes.bool,
  selectButton: PropTypes.any,
  isFileCheck: PropTypes.bool,
  isCustomButton: PropTypes.bool,
  setIsShowAnimate: PropTypes.func,
  errorComponent: PropTypes.func,
  GA: PropTypes.object,
  isUploadPopup: PropTypes.bool,
};

export default FilesUploaderFlatButton;

const UploaderFile = (props) => {
  const {
    state, body, file, onremove, isUploadPopup,
  } = props;

  const $bar = useRef(null);
  const $percent = useRef(null);

  const hasProgressBar = () => [
    UPLOADER_STATES.UPLOADING,
    UPLOADER_STATES.SUCCESS,
    UPLOADER_STATES.ERROR,
    isUploadPopup && UPLOADER_STATES.MULTI,
  ].includes(state);

  const timeout = useRef();

  useEffect(() => () => {
    clearTimeout(timeout.current);
  }, []);

  const LOADING_MAX_PERCENT = 95;

  useEffect(() => {
    const doProgress = () => {
      timeout.current = setTimeout(() => {
        const currentPercent = Number($percent.current.innerHTML.replace('%', ''));
        if (currentPercent >= LOADING_MAX_PERCENT) return;
        const add = Math.floor(Math.random() * 7);
        let p = currentPercent + add;
        if (p >= LOADING_MAX_PERCENT) p = LOADING_MAX_PERCENT;
        $bar.current.style.width = `${p}%`;
        $percent.current.innerHTML = `${p}%`;
        doProgress();
      }, Math.random() * 850);
    };

    switch (state) {
      default:
        break;
      case UPLOADER_STATES.UPLOADING:
        $bar.current.style.width = '0%';
        $bar.current.style.transition = 'width 0.2s ease-out';
        $percent.current.innerHTML = '0%';
        doProgress();
        break;
      case UPLOADER_STATES.ERROR:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
      case UPLOADER_STATES.SUCCESS:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
      case UPLOADER_STATES.MULTI:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
    }
  }, [state]);

  return (
    <div className={`files-uploader-preview ${file.error ? 'files-uploader-preview-error' : ''}`}>
      <div className="files-uploader-preview-header">
        <div>
          <div className="files-uploader-preview-icon">
            <img alt="delete" src={fileIcon} />
          </div>
          <div className="files-uploader-preview-text">
            {file.name}
          </div>
        </div>
        <div>
          { file.error ? (
            <div className="files-uploader-preview-error-tag">
              Error
            </div>
          ) : null}
          { !file.error && onremove ? (
            <div
              className="files-uploader-preview-remove"
              onClick={() => { onremove(file); }}
            >
              <img alt="delete" src={trashIcon} />
            </div>
          ) : null}
        </div>
        { (state === UPLOADER_STATES.SUCCESS && !file.error) ? <div className="ngs-analyze-files-header-status"><img alt="icon" src={CheckIcon} /></div> : null}
      </div>
      {
        hasProgressBar() ? (
          <div className="files-uploader-progress">
            <div className="files-uploader-progress-bar" ref={$bar} />
            <div className="files-uploader-progress-percent" ref={$percent}>0%</div>
          </div>
        ) : null
      }
      { file.error ? (
        <div className="files-uploader-preview-error-message">
          {file.error}
        </div>
      ) : null}
      {body}
    </div>
  );
};

UploaderFile.propTypes = {
  state: PropTypes.any,
  body: PropTypes.any,
  file: PropTypes.any,
  onremove: PropTypes.any,
  isUploadPopup: PropTypes.bool,
};
