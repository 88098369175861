import React from 'react';
import app from 'new-ui/app';
import Button from 'new-ui/Components/Button';
import { HCP_LAST_USER, HCP_STORAGE_KEY } from 'new-ui/Search';
import { render } from 'react-dom';
import {
  POPUP_TYPES, THEMES,
} from 'new-ui/constants';
import { DOM, getOverlay } from 'new-ui/functions';
import Preloader from 'new-ui/Components/Preloader';
import ChatIcon from 'new-ui/assets/icons/chat_bubbles.svg';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import SearchForm, { GENERAL_CONDITION, createGeneralUser } from 'new-ui/Components/SearchForm';
import { AiAssistant } from 'new-ui/Search/AiAssistant';
import { hcpApi } from 'new-ui/Search/api';
import { GA_HCP } from 'new-ui/Search/GA';

export const ELIGIBILITY_OUTCOME = {
  ELIGIBLE: 'ELIGIBLE',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  GONE: 'GONE',
};

export const checkEligibilityByUser = async (user, item, onsuccess)=>{
  const matches = await hcpApi.getMatchesByUser(user);
  item = item?.trialType === 'drug' ? matches.treatments.find(a => a.nct === item.nct) : matches.trials.find(a => a.nct === item.nct) || item;

  if(!item) return ELIGIBILITY_OUTCOME.GONE;

  if (item?.missed_questions) {
    if (!item.missed_questions.length) {
      if (onsuccess) return onsuccess(ELIGIBILITY_OUTCOME.ELIGIBLE);
      return ELIGIBILITY_OUTCOME.ELIGIBLE;
    }else {
      if (onsuccess) return onsuccess(ELIGIBILITY_OUTCOME.NOT_ELIGIBLE);
      return ELIGIBILITY_OUTCOME.NOT_ELIGIBLE;
    }
  }else{
    console.log('warning: item without missed questions');
  }

  if (onsuccess) return onsuccess(null);
};

const openEligibilityPopup = async (item, user, setup)=>{
  const $a = document.querySelector('.hide-ineligible-header');
  if($a) $a.classList.remove('hide-ineligible-header');
  const { t } = window;
  const $overlay = getOverlay();
  $overlay.classList.add('eligibility-popup');
  const $header = DOM('.check-eligibility-popup-header', {});
  $overlay.append($header);
  let isLoading = false;

  render(<Preloader isVisible={isLoading} />, $header);
  const setLoading = (loading) => {
    isLoading = loading;
    render(<Preloader isVisible={isLoading} />, $header);
  };

  render(
    <>
      <div className="el-start">
        <Button
          theme={THEMES.GREY}
          icon={ArrowLeft}
          title={t('general.back')}
          action={()=>{
            app.history.goBack();
            $overlay.remove();
          }}
        />
      </div>
      <div className="el-end">
        <Button
          theme={THEMES.LIGHT}
          title={t('general.cancel')}
          action={()=>{
            $overlay.remove();
          }}
        />
        <Button
          title={t('general.save')}
          theme={THEMES.ORANGE_FULL}
          action={()=>{
            setLoading(true);
            // $overlay.remove();
            localStorage.setItem(HCP_LAST_USER, JSON.stringify(user));
            const fetch = async ()=>{
              checkEligibilityByUser(user, item, (eligibility)=>{
                setLoading(false);
                $overlay.remove();
                setEligibilityClass(eligibility);
                setup.setHeader(
                  getEligibilityHeader(eligibility),
                );
              });
              
            };
            fetch();
          }}
        />
      </div>
    </>, $header,
  );

  const $ai = DOM('.ce-ai', {});
  $overlay.append($ai);

  const upload = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_FILES_CLICKED);
    app.setPopup(POPUP_TYPES.UPLOAD, {
      theme: 'middle',
      onSuccess: async (user)=>{
        window._uploadUser = user;
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_SUCCESS);
        if (app.hcpUser) {
          try {
            await hcpApi.updateUser(user);
          } catch (e) {}
        }
        // TODO:: user
        app.setPopup(false);
      },
    });
  };

  const NGS = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_CLICKED);
    app.setPopup(POPUP_TYPES.NGS, {
      theme: 'middle',
      title: t('ngs.extracting_biomarkers'),
      generatingText: t('ngs.generating_report'),
      uploadText: t('ngs.upload_text'),
      onSuccess: async (biomarkers) => {
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_SUCCESS, {
          biomarkers,
        });
        app.setPopup(false);
      },
    });
  };

  render(
    <AiAssistant uploadAction={upload} ngsAction={NGS} />, $ai,
  );

  const eligibilityData = {
    item,
    user,
    setup,
    className: 'not-eligible',
  };

  window.__eligibilityData = eligibilityData;

  const $intake = DOM('.ce-intake-holder', {});
  $overlay.append($intake);
  if (!user) user = await createGeneralUser();

  let initDisabled = true;
  let initEnabled = false;
  if (user?.personal?.condition === GENERAL_CONDITION) {
    initDisabled = false;
    initEnabled = true;
  }

  render(
    <>
      <SearchForm
        user={user}
        initDisabled={initDisabled}
        initEnabled={initEnabled}
        onAccordionRender={()=>{
          console.log('onAccordionRender', user);
          const allQuestions = document.querySelectorAll('.intake-question');
          allQuestions.forEach(($q)=>{
            const qid = $q.getAttribute('qid');
            if(!item?.missed_questions?.length || !item?.missed_questions?.includes(qid)) {
              return;
            }
            const isAnswered = Boolean($q.getAttribute('data-answered') === 'true');
            if (!isAnswered) {
              $q.classList.add('not-eligible');
            }
          });
        }}
        onChange={()=>{
          console.log('on change');
          localStorage.setItem('eligibility_user', JSON.stringify(user));
        }}
        onLoad={()=>{
          console.log('on load');
        }}
        onBeforeChange={()=>{
          console.log('on before change');
        }}
      />
    </>,
    $intake,
  );
  document.body.appendChild($overlay);
  $overlay.show();
};

export const getEligibilityHeader = (eligibility)=>{
  if(!eligibility) return;
  const { t } = window;
  const lower = eligibility.toLowerCase();
  if (eligibility) {
    return (
      <div className={`item-eligibility-header item-eligibility-${lower}`}>
        { t(`eligibility.${lower}`) }
      </div>
    );
  }
  return null;
};

window.openEligibilityPopup = openEligibilityPopup;

export const setEligibilityClass = (eligibility)=>{
  if(!eligibility) return;
  document.querySelector('header').classList.remove(`item-eligibility-${ELIGIBILITY_OUTCOME.NOT_ELIGIBLE.toLowerCase()}`);
  document.querySelector('header').classList.remove(`item-eligibility-${ELIGIBILITY_OUTCOME.ELIGIBLE.toLowerCase()}`);
  document.querySelector('header').classList.remove(`item-eligibility-${ELIGIBILITY_OUTCOME.GONE.toLowerCase()}`);

  document.querySelector('header').classList.remove(`item-eligibility-${'init'}`);
  document.querySelector('header').classList.add(`item-eligibility-${eligibility.toLowerCase()}`);

  document.querySelector('main').classList.remove(`item-main-eligibility-${ELIGIBILITY_OUTCOME.NOT_ELIGIBLE.toLowerCase()}`);
  document.querySelector('main').classList.remove(`item-main-eligibility-${ELIGIBILITY_OUTCOME.ELIGIBLE.toLowerCase()}`);
  document.querySelector('main').classList.remove(`item-main-eligibility-${ELIGIBILITY_OUTCOME.GONE.toLowerCase()}`);

  document.querySelector('main').classList.remove(`item-main-eligibility-${'init'}`);
  document.querySelector('main').classList.add(`item-main-eligibility-${eligibility.toLowerCase()}`);
};

export const publicItemHeader = async (setup)=>{
  const _l = localStorage.getItem(HCP_STORAGE_KEY);
  let hcpData = null;
  let item;
  let user;
  if(_l){
    try{
      hcpData = JSON.parse(_l);
      item = hcpData.item;
      user = hcpData.user;
    }catch(e){}
  }
  window._hcpData = hcpData;
  if(hcpData) document.querySelector('main').classList.add('from-hcp');
  // setEligibilityClass(ELIGIBILITY_OUTCOME.NOT_ELIGIBLE);
  const params = app.getParams();
  const shouldOpenEligibility = params.get('e');

  if(shouldOpenEligibility && item){
    if(item?.missed_questions?.length) openEligibilityPopup(item, user, setup);
    else setup.setHeader(getEligibilityHeader(ELIGIBILITY_OUTCOME.ELIGIBLE));
    return;
  }
  if (_l && hcpData) {
    const { item, user } = hcpData;
    if(item.missed_questions && !item.missed_questions.length) setup.setHeader(
      getEligibilityHeader(ELIGIBILITY_OUTCOME.ELIGIBLE)
    );

    const consultWithUs = ()=>{
      app.setPopup(POPUP_TYPES.CONSULT_WITH_US, { user });
    }

    app.setHeader(
      <div className="public-trial-header-menu">
        <Button
          title={window.t('general.check_qualification')}
          className="check-qualification-button"
          action={()=>{
            openEligibilityPopup(item, user, setup);
          }}
        />
        <Button
          theme={THEMES.BLACK}
          icon={ChatIcon}
          title={window.t('general.talk_to_us')}
          action={()=>{
            if(app.user){
              consultWithUs();
            }else{
              app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
                theme: 'middle',
                onSignup: (user)=>{
                  app.user = user;
                  consultWithUs();
                },
              });
            }
          }}
        />
      </div>,
    );

    const $header = document.querySelector('header');
    const $d = DOM('.eligibility-header-back', {});
    document.body.classList.add('eligibility-body');
    render(<Button
      theme={THEMES.GREY}
      className="trial-back-button"
      action={()=>{
        app.history.goBack();
      }}
      icon={ArrowLeft}
      title={window.t('general.back_to_results')}
    />, $d);
    $header.prepend($d);
    localStorage.removeItem(HCP_STORAGE_KEY);
  }
};
