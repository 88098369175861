/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import ResultList from 'new-ui/Results/ResultList';
import Button from 'new-ui/Components/Button';
import {
  POPUP_TYPES, ROUTES, PAGES, THEMES, URI,
  DEFAULT_INDICATION,
} from 'new-ui/constants';
import GlassIcon from 'new-ui/assets/icons/glass-white.svg';
import { ReactComponent as SearchIconBlack } from 'new-ui/assets/icons/search-zoom-in-black.svg';
import { ReactComponent as PatientsIcon } from 'new-ui/assets/icons/patients.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user2.svg';
import { ReactComponent as LoginIcon } from 'new-ui/assets/icons/login-button.svg';
import { ReactComponent as UserTickIcon } from 'new-ui/assets/icons/user_tick.svg';
import { ReactComponent as SearchIcon } from 'new-ui/assets/icons/search-zoom-in.svg';
import {
  DOM,
  createEmptyUser,
  getInitialConditionProfileByIndication, getLoggedUser,
  getOverlay,
  randomString,
} from 'new-ui/functions';
import { useHistory } from 'react-router-dom';
import './Search.css';
import ResultsSkeleton from 'new-ui/Results/ResultList/ResultsSkeleton';
import { USER_TYPES, USER_TYPES_VALUES } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';
import { getQstack } from 'new-ui/Components/Intake/functions';
import useForceUpdate from 'use-force-update';
import { getFinanceParams } from 'new-ui/Pages/Finance/functions';
import ProfileButton from 'new-ui/Components/ProfileButton';
import SearchForm, { GENERAL_CONDITION, createGeneralUser } from 'new-ui/Components/SearchForm';
import { render } from 'react-dom';
// import { updateQStack } from 'components/Questionnaire/actions';
import UploadIcon from '../Pages/Upload/assets/upload-files.svg';
import { ReactComponent as StarImg } from '../assets/icons/star.svg';
import AnalyzeIcon from '../assets/icons/analyze.svg';
import { addUserDocuments } from '../../modules/userDocuments/api';
import { hcpApi } from './api';
import { GA_HCP } from './GA';
import SaveSearchPopup from './SaveSearch';

export const MODES = {
  TREATMENTS: 'TREATMENTS',
  TRIALS: 'TRIALS',
};

export const MODES_TITLES = {
  [MODES.TREATMENTS]: 'Standard Treatments',
  [MODES.TRIALS]: 'Clinical Trials',
};

export const MODES_TABS = {
  [MODES.TREATMENTS]: 'treatments',
  [MODES.TRIALS]: 'trials',
};

export const HCP_STORAGE_KEY = 'hcp_data';
export const HCP_LAST_USER = 'hcp_last_user';

export const getNewPatientEmail = (uid)=>{
  return `patient_of_${uid}_${randomString()}@leal.health`;
};

export const addCaregiver = ({ onSignup, onSignupReturn })=>{
  app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
    theme: 'middle',
    onSignup: async (_user) => {
      app.setShowPreloader(true);
      // app.setPopup(false);
      // history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
      app.user = _user;

      if (onSignup) onSignup(_user);
      if(onSignupReturn) return onSignupReturn(_user);

      const documents = app.getDocuments();
      const profileId = _user?.personal?.profile_id;

      if (documents && profileId) {
        addUserDocuments({
          ...documents,
          profileId,
        });
      }

      // todo create finance caregiver user
      const registerFinanceCaregiver = await hcpApi.registerFinanceCaregiver();

      console.log('registerFinanceCaregiver response', registerFinanceCaregiver);

      app.setShowPreloader(false);

      addPatient({
        caregiver: app.user,
        defaultEmail: getNewPatientEmail(app.user.personal.user_wix_id),
        indication: app.hcpUser?.personal.condition,
      });
    },

  });
};

export const addPatient = async (props)=>{
  const {
    caregiver = app?.user,
    name = '',
    email = '',
    emailRequired = false,
    defaultEmail = getNewPatientEmail(app?.user?.personal?.user_wix_id),
    indicationTitle,
    nameTitle,
    emailTitle,
    buttonTitle,
    doLogin,
    title,
    subtitle,
    onBeforeSignup = async (patient)=>{
      console.log('addPatient', patient);
    },
    onSignup = async (patient, setError)=>{
      try {
        app.setShowPreloader(true);
        app.hcpUser = patient;
        const addPatientResults = await hcpApi.addPatient(patient, app.user);
        if (addPatientResults?.success !== true) {
          return setError('Oops, something went wrong 😅');
        }
        const patientFinanceParams = await getFinanceParams(patient);
        try {
          await hcpApi.createFinancePatient(patientFinanceParams);
        } catch (e) {
          debugger;
        }
        window.location = URI[PAGES.PATIENTS];
      } catch (e) {
        setError('Oops, something is not working! 😅');
      }
      // debugger;
      // app.history.push(ROUTES[PAGES.PATIENTS]);
    },
  } = props;
  let {
    indication = app?.hcpUser?.personal?.condition || window?.__intakeUser?.personal?.condition,
  } = props;
  if (indication === GENERAL_CONDITION) indication = null;
  app.setPopup(POPUP_TYPES.ADD_PATIENT, {
    theme: 'middle',
    caregiver,
    name,
    email,
    defaultEmail,
    onBeforeSignup,
    emailRequired,
    indication,
    onSignup,
    indicationTitle,
    nameTitle,
    emailTitle,
    buttonTitle,
    doLogin,
    title,
    subtitle,
  });
};

const SearchTrials = () => {
  const { t } = useTranslation();
  // const [mode, setMode] = useState(MODES.TRIALS);
  // const [showMobileTabs, setShowMobileTabs] = useState(false);
  // const [showMobileActions, setShowMobileActions] = useState(false);
  const [showPreloader, setShowPreloader] = useState(true);
  // eslint-disable-next-line
  const [emptyResults, setEmptyResults] = useState(false);
  const [data, setData] = useState(null);
  // const [patient, setPatient] = useState(null);
  const update = useForceUpdate();
  window.__update = update;

  const [
    user,
    setUser,
  ] = useState(getLoggedUser());
  const history = useHistory();

  delete app.cache.itemData;

  useEffect(()=>{
    const _urlParams = app.getParams();
    app.intercom.hidden = true;
    const fetch = async ()=>{
      console.log('search fetch');
      let user;
      let initEnabled;
      await app.getSession();
      if (app.user) {
        if (_urlParams.get('new')) {
          delete app.hcpUser;
          localStorage.removeItem('set_patient')
          localStorage.removeItem(HCP_LAST_USER)
        } else if (_urlParams.get('save') && localStorage.getItem(HCP_LAST_USER)) {
          app.hcpUser = JSON.parse(localStorage.getItem(HCP_LAST_USER));
          user = app.hcpUser;
          addPatient({
            caregiver: app.user,
            indication: app.hcpUser?.personal?.condition,
            defaultEmail: getNewPatientEmail(app.user.personal.user_wix_id),
          });
        } else if (_urlParams.get('indication')) {
          delete app.hcpUser;
          const _user = await createEmptyUser(_urlParams.get('indication'));
          user = _user;
        } else if (localStorage.getItem('set_patient') && app.user.patients?.length) {
          const SET_PATIENT = localStorage.getItem('set_patient');
          app.hcpUser = app.user.patients.find((p)=>p.info.user_wix_id === SET_PATIENT);
          user = app.hcpUser;
        } else if (!app.hcpUser && app?.user?.patients?.length) {
          app.hcpUser = app.user.patients[0];
          user = app.hcpUser;
        }
      } else {
        const _lastUser = localStorage.getItem(HCP_LAST_USER);
        if (_lastUser) {
          try {
            user = JSON.parse(_lastUser);
            initEnabled = true;
          } catch (e) {}
        }
      }

      if(!user && localStorage.getItem(HCP_LAST_USER)) {
        const _lastUser = localStorage.getItem(HCP_LAST_USER);
        if (_lastUser) {
          try {
            user = JSON.parse(_lastUser);
            initEnabled = true;
          } catch (e) {}
        }
      }

      renderSearchForm(user, initEnabled);
    };
    if (_urlParams.get('add')) {
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SIGNUP_POPUP);
      save();
      return;
    }
    fetch();
    localStorage.removeItem(HCP_STORAGE_KEY);
    // eslint-disable-next-line
  }, []);

  // useEffect(()=>{
  //   getResults(user);
  // }, [user]);

  const setHeader = ()=>{
    app.setCustomHeader((
      <>
        <div className="header-buttons">
          {
            user
              ? app.hcpUser ? (
                <Button theme={THEMES.BLACK} title={t('general.new')} action={handleNewClick} customIcon={<SearchIcon />} />
              ) : (
                <Button theme={THEMES.BLACK} title={t('search.save')} action={addPatient} customIcon={<UserTickIcon />} />
              )
              : <Button theme={THEMES.BLACK} title={t('search.save')} action={save} customIcon={<UserTickIcon />} />
          }
          {
            user
              ? (
                <Button
                  theme={THEMES.GREY}
                  title={t('general.patients')}
                  action={()=>{
                    window.location = URI[PAGES.PATIENTS];
                  }}
                  customIcon={<PatientsIcon />}
                />
              )
              : <Button theme={THEMES.GREY} title={t('general.new')} action={handleNewClick} customIcon={<SearchIconBlack />} />
          }

          {
            // <Button theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserTickIcon />} />
            !user ? (
              <>
                <Button className="mobile-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<LoginIcon />} />
                <Button className="desktop-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserIcon />} />
              </>
            ) : (
              <ProfileButton />
            )
          }
          {/* <Button theme={THEMES.GREY} title={t('search.goto_dashboard')} action={gotoDashboard} customIcon={<CheckIcon />} /> */}
        </div>
      </>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setHeader();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => () => {
    app.setCustomHeader();
  }, []);

  const gotoLogin = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.LOGIN_CLICKED);
    app.history.push(`${ROUTES[PAGES.LOGIN]}?redirect=/search`);
  };

  const handleNewClick = () => {
    app.setPopup(POPUP_TYPES.GENERAL, {
      theme: 'middle',
      title: t('save_profile.title'),
      content: <SaveSearchPopup
        handleContinueWithoutSaving={()=>{
          app.setPopup(false);
          doNew();
        }}
        handleSaveAndContinue={(p)=>{
          app.setPopup(false);
          doNew();
          if (app.user) {
            addPatient(p);
          } else {
            save();
          }
        }}
      />,
    });
  };

  const doNew = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NEW_CLICKED);
    delete app.hcpUser;
    localStorage.removeItem('set_patient');
    localStorage.removeItem(HCP_LAST_USER);
    setHeader();
    const fetch = async ()=>{
      const _user = await createGeneralUser();
      renderSearchForm(_user);
    };
    fetch();
  };

  window.doNew = doNew;

  const createPatientFromParams = async (searchParams = {})=>{
    app.setShowPreloader(true);
    let o = await hcpApi.params2user(searchParams, searchParams.condition);
    const p = await getInitialConditionProfileByIndication(searchParams.condition);

    o = o.data;

    const newUser = {
      condition_profile: p,
      personal: {},
      info: {
        email: getNewPatientEmail(app.user.personal.user_wix_id),
        user_type: USER_TYPES_VALUES[USER_TYPES.PATIENT],
      },
    };

    newUser.condition_profile = Object.assign(newUser.condition_profile, o.condition_profile);
    newUser.info = Object.assign(newUser.info, o.info);
    newUser.personal = Object.assign(newUser.personal, o.personal);
    newUser.qstack = await getQstack(newUser, await app.getQuestions(searchParams.condition || DEFAULT_INDICATION));
    app.setShowPreloader(false);

    console.log('new user for addPatient', newUser);

    return newUser;
  };

  window.__createPatientFromParams = createPatientFromParams;

  const save = async () => {
    const patient = window.__intakeUser;
    addCaregiver({
      patient,
      onSignup: ()=>{
        setUser(app.user);
      },
    });
  };

  const itemClick = (item) => {
    const isTreatment = item.trialType === 'drug';
    localStorage.setItem(HCP_STORAGE_KEY, JSON.stringify({
      item,
      user: window.__intakeUser,
    }));
    history.push(`${ROUTES[!isTreatment ? PAGES.PUBLIC_TRIAL : PAGES.PUBLIC_TREATMENT]}/${item.nct_number}`);
  };

  const upload = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_FILES_CLICKED);
    app.setPopup(POPUP_TYPES.UPLOAD, {
      theme: 'middle',
      onSuccess: async (user)=>{
        window._uploadUser = user;
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_SUCCESS);
        if (app.hcpUser) {
          try {
            await hcpApi.updateUser(user);
          } catch (e) {}
        }
        renderSearchForm(user);
      },
    });
  };

  const renderSearchAccordion = ()=>{
    if (window._accordions?.search?.render) window._accordions.search.render();
  };

  const NGS = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_CLICKED);
    app.setPopup(POPUP_TYPES.NGS, {
      theme: 'middle',
      title: t('ngs.extracting_biomarkers'),
      generatingText: t('ngs.generating_report'),
      uploadText: t('ngs.upload_text'),
      onSuccess: async (biomarkers) => {
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_SUCCESS, {
          biomarkers,
        });
        if (app?.hcpUser?.condition_profile) {
          app.hcpUser.condition_profile.biomarkers = biomarkers;
          try {
            getResults(app.hcpUser);
            await hcpApi.updateUser(app.hcpUser);
            setShowPreloader(true);
            if (window?.__intakeUser?.condition_profile) window.__intakeUser.condition_profile.biomarkers = biomarkers;
            renderSearchAccordion();
            return;
          } catch (e) {}
        } else {
          if (window?.__intakeUser?.condition_profile) window.__intakeUser.condition_profile.biomarkers = biomarkers;
          renderSearchAccordion();
          setShowPreloader(true);
          getResults(window.__intakeUser);
        }
        if (window?._accordion) window._accordion.render();
        app.setPopup(false);
      },
    });
  };

  const getResults = async (_user)=>{
    if (!_user) return;
    localStorage.setItem(HCP_LAST_USER, JSON.stringify({ ..._user }));
    console.log('Search :: before getResults :: user', JSON.parse(JSON.stringify(_user)));
    const results = await hcpApi.getMatchesByUser(_user);
    console.log('Search :: getResults', results);
    setResults(results);
    setShowPreloader(false);
  };

  const setResults = async (data)=>{
    setData(data);
    setShowPreloader(false);
  };

  const updateUser = async (_user)=>{
    const questions = await app.getQuestions(_user.personal.condition, true);
    const qs = getQstack(_user, questions);
    _user.condition_profile.qstack = qs;
    await hcpApi.updateUser(_user);
    app.setQuickNote({
      title: t('hcp.patient_updated_success'),
    });
  };

  const renderSearchForm = (user, initEnabled = false)=>{
    const $container = document.querySelector('.search-form-container');
    if(user?.condition_profile?.biomarkers === null || user?.condition_profile?.biomarkers?.length === 0){
      user.condition_profile.biomarkers = ['none'];
    }
    render(<SearchForm
      user={user}
      initEnabled={initEnabled}
      onLoad={(_user)=>{
        getResults(_user);
      }}
      onBeforeChange={()=>{
        setShowPreloader(true);
      }}
      onChange={(_user)=>{
        console.log('Search :: SearchForm :: onChange', _user);
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.FILTER_CHANGED)
        setShowPreloader(true);
        getResults(_user);
        app.hcpUser = _user;
        updateUser(_user);
      }}
    />, $container);
  };

  window._renderSearchForm = renderSearchForm;

  const openMobileForm = ()=>{
    const $overlay = getOverlay();
    const $form = document.querySelector('.hcp-form');
    document.body.appendChild($overlay);
    $overlay.classList.add('hcp-mobile-search');
    const $header = DOM('.search-form-mobile-header', {
      '/.search-form-mobile-header-title': {},
      '/.search-form-mobile-header-close': {
        innerHTML: 'X',
        onclick: ()=>{
          document.querySelector('.search-form-container').appendChild($form);
          $overlay.remove();
        },
      },
    });
    $overlay.append($header);
    $overlay.append($form);
  };

  return (
    <div className={`search ${app.hcpUser ? 'hcp-user' : ''}`}>
      <div className="upload-nav">
        <div className="info">
          <div className="result-list-mobile-header-options mobile-only">
            <div
              className="result-list-mobile-header-glass"
              onClick={() => {
                openMobileForm();
              }}
            >
              <img alt="" src={GlassIcon} />
            </div>
          </div>
          <StarImg />
          <div className="desktop-only">{t('general.ai.assistant')}</div>
          <div className="buttons">
            <Button
              action={upload}
              backgroundColor="#C77DFF"
              title={t('hcp.bar_upload_files')}
              icon={UploadIcon}
              className="upload-files desktop-only"
              width="210"
            />
            <Button
              action={upload}
              backgroundColor="#C77DFF"
              title={t('hcp.bar_upload_files_mobile')}
              className="upload-files mobile-only"
              width="210"
            />
            <div className="or desktop-only">{t('general.or')}</div>
            <Button
              backgroundColor="#C77DFF"
              title={t('hcp.bar_ngs_mobile')}
              className="upload-files desktop-only"
              icon={AnalyzeIcon}
              width="210"
              action={NGS}
            />
            <Button
              backgroundColor="#C77DFF"
              title={t('hcp.bar_ngs_mobile')}
              className="upload-files mobile-only"
              width="210"
              action={NGS}
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="results-container">
          { emptyResults ? <div className="search-empty">{t('search.no_results')}</div> : null}
          { showPreloader
            ? <ResultsSkeleton />
            : (
              data ? (
                <ResultList
                  getResultsClass={()=>{
                    if(window?.__intakeUser?.info?.condition === GENERAL_CONDITION) return 'general-condition';
                    return '';
                  }}
                  isSearchPage
                  customNoData={(
                    <div className="hcp-no-data">
                      Looks like there are no matches for this specific search...
                    </div>
                  )}
                  all={data}
                  action={itemClick}
                />
              ) : null
            )}
        </div>
        <div className="avoid-break search-actions">
          <div className="desktop-only">
            <div className="search-form-container" />
            <div className="height-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTrials;

window._mockUser = {
  condition_profile: {
    biomarkers: [
      'pdl1',
      'egfr',
    ],
    braf_mutation: null,
    current_stage: [
      '4.1',
    ],
    disease_location: [
      'lymph_nodes',
      'pleural_effusion',
      'lungs',
    ],
    disease_status: null,
    drugs_received: null,
    egft_mut: [
      'g719a',
      'other',
    ],
    erbb2_her2_mut: null,
    kras_mut: null,
    met_mut: null,
    qstack: [
      'dob',
      'gender',
      'met_mut',
      'egft_mut',
      'kras_mut',
      'biomarkers',
      'family_name',
      'braf_mutation',
      'current_stage',
      'type_of_nsclc',
      'disease_status',
      'drugs_received',
      'erbb2_her2_mut',
      'disease_location',
      'type_of_lung_cancer',
      'treatment_for_lung_cancer',
      'treatment_for_advanced_metastatic_disease',
    ],
    treatment_for_advanced_metastatic_disease: null,
    treatment_for_lung_cancer: [
      'yes',
    ],
    type_of_lung_cancer: [
      'nsclc',
    ],
    type_of_nsclc: [
      'adenocarcinoma',
    ],
  },
  info: {
    family_name: null,
  },
  personal: {
    condition: 'lung',
    dob: null,
    gender: 'female',
  },
};
