/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable object-curly-newline */

import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';
import app from 'new-ui/app';
import { SurveyTypes, SurveyAnswerKeys, SurveyAppointmentRepeatAnswerValues, SurveyStatus } from './surveyEnums'; 

const apiUrl = configs.beApiUrl;

export const addEvent = async (data) => {
  const user = await app.getUser();
  const userWixId = user.info.user_wix_id;
  let nextAppointmentValue = '';

  if (data.month && data.day && data.year) { // Check if all date components are present
    nextAppointmentValue = `${data.year}-${data.month}-${data.day}`;
  }
  // Transform frontend data to match backend SurveyDto
  const surveyData = {
    userWixId,
    type: SurveyTypes[data.eventType] || SurveyTypes.Scan, // Default to Scan if eventType is invalid
    surveyAnswers: [
      {
        surveyKey: 'appointmentDate',
        key: SurveyAnswerKeys.nextAppointment,
        value: nextAppointmentValue, // Use the conditionally set value
      },
      {
        surveyKey: 'treatmentCycle',
        key: SurveyAnswerKeys.cycle,
        value: data.cycle || '1',
      },
      {
        surveyKey: 'repeatFrequency',
        key: SurveyAnswerKeys.repeat,
        value: SurveyAppointmentRepeatAnswerValues[data.repeatStatus] || SurveyAppointmentRepeatAnswerValues.none,
      },
    ],
  };

  // Special handling for "scan" eventType: Remove cycle and repeat if not relevant
  if (data.eventType === 'scan') {
    surveyData.surveyAnswers = surveyData.surveyAnswers.filter((answer) => answer.key === SurveyAnswerKeys.nextAppointment);
  }

  console.log('surveyData: ', surveyData);

  const requestOptions = {
    url: `${apiUrl}/survey`,
    headers: await authHeaders(),
    data: surveyData,
    json: true,
  };

  try {
    const response = await request.post(requestOptions);
    if (response && response.success) {
      return { message: 'Event Added', newEvent: response.data };
    }
    console.error('Error adding event:', response?.error || 'Unknown error');
    throw new Error('Failed to add event');
  } catch (ex) {
    console.error('Error occurred while adding event', ex);
    throw ex;
  }
};

export const deleteEvent = async (id) => {
  const requestOptions = {
    url: `${apiUrl}/survey/${id}`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    const response = await request.del(requestOptions);
    if (response && response.success) {
      return { message: 'Event Deleted', id };
    }
    console.error('Error deleting event:', response?.error || 'Unknown error');
    throw new Error('Failed to delete event');
  } catch (ex) {
    console.error('Error occurred while deleting event', ex);
    throw ex;
  }
};

export const getEvents = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/survey`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);

    if (response && response.success) {
      return response.data.map((event) => {
        const appointmentDate = event.surveyAnswers.find((answer) => answer.key === 'nextAppointment')?.value;
        const currentDate = new Date();
        const eventDate = new Date(appointmentDate);

        let status = SurveyStatus.UPCOMING; // Default to upcoming

        if (eventDate < currentDate) {
          status = SurveyStatus.EXPIRED;
        }

        return {
          id: event.id,
          type: event.type === 'clinicVisit' ? 'clinic_visit' : event.type,
          date: appointmentDate,
          cycle: event.surveyAnswers.find((answer) => answer.key === 'cycle')?.value,
          repeatStatus: event.surveyAnswers.find((answer) => answer.key === 'repeat')?.value,
          status, // Use the determined status
        };
      });
    }
    console.error('Error getting events:', response?.error || 'Unknown error');
    return [];
  } catch (ex) {
    console.error('Error occurred while getting events', ex);
    return [];
  }
};
