
import React, { useRef, useState, useEffect } from 'react';
import './Select.css';
import { useTranslation } from 'utils/modifiedTranslation';
import { randomString } from 'new-ui/functions';
import { isArray, isString } from 'lodash';
import ArrowDown from 'new-ui/assets/icons/arrow-circle-black.svg';
import XIcon from 'new-ui/assets/icons/x.svg';
import { Tag } from 'new-ui/Components/Tags';
import addIcon from 'new-ui/assets/icons/AddIcon.svg';

const HCPSelect = (props) => {
  const {
    renderOnSelect = true,
    disabled = false,
    showAutofill = true,
    onselect,
    initValue,
    options,
    icon,
    isMulti = false,
    useTags = false,
    placeholder,
    className,
    initLabel
  } = props;

  const [value, setValue] = useState(initValue);
  const [label, setLabel] = useState(initLabel);
  const [showTags, setShowTags] = useState(!!useTags && initValue?.length);
  const { t } = useTranslation();
  const isOptionSelected = (option)=>{
    if (isArray(value)) return value.includes(option.value);
  };
  const $select = useRef();

  const [showOptions, setShowOptions] = useState(false);

  // This useEffect will add an event listener for clicks outside the select box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if ($select.current && !$select.current.contains(event.target)) {
        setShowOptions(false); // Close dropdown if click is outside of the component
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [$select]);

  useEffect(()=>{
    const $current = $select?.current;
    if ($current) {
      const $title = $current.querySelector('.hcp-select-title');
      $title.innerHTML = getTitle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getOptions = () => {
    return options;
  };

  const getOptionTitle = (option) => {
    return option.title || option.label;
  };

  const clear = (ev) => {
    ev.stopPropagation();
    setValue(null);
    setShowOptions(false);
    onselect(null);
  };

  
  const getSelecteds = () => {
    return options.filter((o) => {
      if (isArray(value)) return value.includes(o.value);
      if (isString(value)) return o.value === value;
      return false;
    });
  };

  const getTitle = () => {
    const selecteds = getSelecteds();
    if (!selecteds?.length) return placeholder || 'Please select...';
    console.log(selecteds);
    if (isMulti) {
      return selecteds.map((s)=>{
        return s.title || s.label;
      }).join(', ');
    }

    if(className === 'hcp-select-indication' && label) {
      return label;
    }
    return selecteds[0].title || selecteds[0].label;
  };

  const callOnSelect = (v, l=null)=>{
    onselect(v, l);
    if (renderOnSelect) window._accordion.render();
  };

  return (
    <>
      { (useTags && isMulti && value?.length && showTags) ? (
        <div className="hcp-tags select-tags-box">
          { value.map((v, i)=>{
            const o = options.find((o)=>o.value === v);
            // eslint-disable-next-line
            if (!o) return;
            return (
              <Tag
                key={i}
                title={o.title || o.label}
                value={v}
                action={(v)=>{
                  const newValue = [...value];
                  newValue.splice(newValue.indexOf(v), 1);
                  setValue(newValue);
                  onselect(newValue);
                  if (newValue.length) return;
                  setShowTags(false);
                }}
              />
            );
          }) }
          <div
            onClick={()=>{
              setShowTags(false);
              setTimeout(()=>{
                if (showAutofill) $select.current.querySelector('input').focus();
              }, 10);
              setShowOptions(true);
            }}
            className="add-tag"
          >
            <img src={addIcon} alt="add icon" />
            <span
              className="select-add-tag"
            >
              {t('search.search_biomarkers_tag_selector_button')}
            </span>
          </div>
        </div>
      ) : null }
      {
        !showTags
          ? (
            <div
              ref={$select}
              key={randomString()}
              className={`hcp-select ${className || ''} ${showOptions ? 'hcp-select-visible' : ''} ${(value && value.length) ? 'hcp-select-value' : ''} ${disabled ? 'hcp-select-disabled' : ''}`}
            >
              <div
                className="hcp-select-header"
                onClick={() => {
                  const s = !showOptions;
                  if (s) {
                    setTimeout(()=>{
                      if (showAutofill) $select.current.querySelector('input').focus();
                    }, 10);
                  }
                  setShowOptions(s);
                }}
              >
                <div className="hcp-select-title">{getTitle()}</div>
                <div className="hcp-select-icon hcp-select-icon-hide" onClick={clear}>
                  <img src={XIcon} alt="" />
                </div>
                <div className="hcp-select-icon hcp-select-icon-main">
                  {
                    icon ? <img src={icon} alt="" /> : <img className="hcp-select-icon-down" src={ArrowDown} alt="" />
                  }
                </div>
              </div>
              {showOptions && (
                <div className="hcp-select-body">
                  {
                    showAutofill ? (
                      <div className="hcp-select-input">
                        <input
                          onKeyUp={(ev) => {
                            const v = ev.currentTarget.value;
                            const $options = [...$select.current.querySelectorAll('.hcp-select-option')];
                            $options.forEach(($option) => {
                              const $title = $option.querySelector('.hcp-select-option-title');
                              if ($title.innerHTML.toLowerCase().indexOf(v.toLowerCase()) === -1) $option.classList.add('hidden');
                              else $option.classList.remove('hidden');
                            });
                          }}
                          placeholder={t('general.start_typing')}
                        />
                      </div>
                    ) : null
                  }
                  <div className="hcp-select-clear hcp-select-option" onClick={clear}>
                    <div className="hcp-select-option-title">
                      {t('general.clear_selection')}
                    </div>
                  </div>
                  <div className="hcp-select-options">
                    {getOptions().map((option) => {
                      return (
                        <div
                          key={randomString()}
                          className={`hcp-select-option ${isOptionSelected(option) ? 'hcp-select-option-selected' : ''}`}
                          onClick={() => {
                            if (isMulti) {
                            // Multi-select logic here
                              if (!value) {
                              // Initialize value as an array if it's currently null or undefined
                                const _newValue = [option.value];
                                setValue(_newValue);
                                callOnSelect(_newValue); // Pass the updated array to the onselect callback
                              } else if (Array.isArray(value)) {
                                const isSelected = value.includes(option.value);
                                let updatedValue;
                                if (isSelected) {
                                  updatedValue = value.filter((v) => v !== option.value);
                                } else {
                                  updatedValue = [...value, option.value];
                                }
                                setValue(updatedValue);
                                callOnSelect(updatedValue); // Pass the updated array to the onselect callback
                              } else if (isString(value)) setValue([value]);
                              setShowOptions(false); // Close the dropdown after selection
                              if (useTags) setShowTags(true);
                            } else {
                            // Single-select logic
                              setValue(option.value);
                              setLabel(option.label);
                              callOnSelect(option.value, option.label);
                              setShowOptions(false);
                            }
                          }}
                        >
                          <div className="hcp-select-option-title">
                            {getOptionTitle(option)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )
          : null
      }
    </>
  );
};

export default HCPSelect;
