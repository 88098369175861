import app from "new-ui/app";
import './EditQuestion.css';
import Intake from "new-ui/Components/Intake";
import { waitFor } from "new-ui/functions";
import React, { useEffect, useRef } from "react";
import { render } from "react-dom";

const EditQuestion = ({ question, onsave })=>{

    const $ = useRef();

    useEffect(()=>{
        const fetch = async ()=>{
            const customQuestions = [question];
            waitFor(()=>{
                return app.user
            }, ()=>{
                render(
                    <Intake 
                        className='single-question-intake' 
                        user={app.user} 
                        flatMode
                        expandAll
                        customQuestions={customQuestions}
                        customOnSelect={async (newUser)=>{
                            // app.user.condition_profile[question] = newUser.condition_profile[question];
                            app.updateUser();
                            if(onsave) onsave(app.user);
                        }}
                        gotoResultsAction={()=>{
                            app.setPopup(false);
                            app.render();
                        }}
                    />,
                    $.current,
                )
            }, ()=>{
                
            })
            
        }
        fetch();
    }, []);
    return <div ref={$} className="edit-question-popup"></div>
};

export default EditQuestion;
