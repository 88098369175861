import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
// import ChatPopup from 'new-ui/Popups/Chat';
import './chat.css';
import ChatNewUserPopup from 'new-ui/Popups/ChatNewUser';

const ChatPage = () => {
  // const { t } = useTranslation();
  const [init, setInit] = useState(null);

  useEffect(() =>{
    const fetch = async () => {
      if (!app.user) {
        app.setShowPreloader(true);
        app.user = await app.getUser();
        app.setShowPreloader(false);
        setInit(true);
      } else {
        setInit(true);
      }
    };

    fetch();
  }, []);
  return (
    init ? (
      <>
        <div className="full-screen-chat">
          <div className="full-screen-chat-width"><ChatNewUserPopup isFullMode /></div>
        </div>
      </>
    ) : null

  );
};

export default ChatPage;
