/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable arrow-parens */
import app from 'new-ui/app';
import { formatTextToUseNewLineBeforeNumbers, updateChatHistory } from '../Chat/functions';
import { sendMessageReturnUser } from './api';
import { CHAT_ROLES } from 'new-ui/constants';

/* eslint-disable no-console */

function updateOriginalJson(originalJson, modifiedJson) {
  let changesMade = false;

  for (const key in modifiedJson) {
    if (
      modifiedJson.hasOwnProperty(key) &&
      originalJson.hasOwnProperty(key) &&
      JSON.stringify(originalJson[key]) !== JSON.stringify(modifiedJson[key])
    ) {
      originalJson[key] = modifiedJson[key];
      changesMade = true;
    }
  }

  return changesMade;
}

const getUserSegment = () => {
  let segment = app?.coniguration?.data?.userIntent?.user_type;
  if (!segment) {
    segment = 'treatment';
  } else {
    segment = segment.toLowerCase(); // Convert segment to lowercase
  }
  return segment;
};

// const removeNullEmpty = data => {
//   if (typeof data === 'object' && data !== null) {
//     if (Array.isArray(data)) {
//       return data.filter(item => item !== null).map(removeNullEmpty);
//     }
//     const result = {};
//     for (const key in data) {
//       const value = removeNullEmpty(data[key]);
//       if (value !== null && (!Array.isArray(value) || value.length > 0)) {
//         result[key] = value;
//       }
//     }
//     return result;
//   }
//   return data;
// };

export const formmatedlData = (
  data,
  trials,
  userHaveSponseredTrialList,
  userSponsoredTrials,
  socTreatmentsCategories,
  socTreatments,
  newSession
) => {
  try {
    const toReturn = data;
    delete toReturn?.personal?.temp_id;
    delete toReturn?.personal?.is_done;
    delete toReturn?.personal?.answered_no_on_has_treatment_date;
    delete toReturn?.personal?.user_role;
    delete toReturn?.personal?.trialsCountBeforeExtraFilter;
    delete toReturn?.personal?.documents_data;
    delete toReturn?.personal?.providerCode;
    delete toReturn?.personal?.providerConsent;
    delete toReturn?.personal?.looking_for;

    // const temp = removeNullEmpty(toReturn.condition_profile);
    // toReturn.condition_profile = temp;

    delete toReturn?.condition_profile?.temp_id;

    toReturn.info.Fulladdress = toReturn?.info?.country?.label;
    toReturn.info.addressLat = toReturn?.info?.country?.location?.lat;
    toReturn.info.addressLng = toReturn?.info?.country?.location?.lng;
    delete toReturn?.info?.country;
    toReturn.socTreatments = app.treatments.slice(0, 15);
    toReturn.userNctList = trials;
    toReturn.numberOfTrialList = trials?.length ?? 0;
    toReturn.numberOfApprovedDrugs = app.treatments?.length ?? 0;
    toReturn.userSponsoredTrials = userSponsoredTrials;
    toReturn.userHaveSponseredTrialList = userHaveSponseredTrialList;
    toReturn.socTreatmentsCategories = socTreatmentsCategories;
    toReturn.userSegment = getUserSegment();

    toReturn.newSession = newSession;
    return toReturn;
  } catch (error) {
    console.log('🚀 ~ formmatedlData ~ error:', error);
  }
};

export const handleSendMessageByReturnUser = async (
  prompt,
  payload,
  setChatHistory,
  chatHistory,
  dontIncludeInHistory
) => {
  console.log('app.user.personal:', app.user.personal);

  if (prompt && !dontIncludeInHistory) {
    updateChatHistory(setChatHistory, chatHistory, ` ${formatTextToUseNewLineBeforeNumbers(prompt)}`, CHAT_ROLES.ME);
  }
  const response = await sendMessageReturnUser(prompt, payload);
  updateChatHistory(
    setChatHistory,
    chatHistory,
    ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
    CHAT_ROLES.TINA
  );

  if (response.dataToUpdate) {
    updateOriginalJson(app.user.condition_profile, response.dataToUpdate);
    // console.log('🚀 ~ isUpdate:', isUpdate);
    if (response.triggerProfileUpload === '1') {
      console.log('app.user.condition_profile:', app.user.condition_profile);
      await app.updateUser();
    }
  }

  if (response.triggerTrialListUpdate === '1') {

    await app.updateUser();

    const response = await sendMessageReturnUser(' Give me list of updated trials', payload);
    updateChatHistory(
      setChatHistory,
      chatHistory,
      ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
      CHAT_ROLES.TINA
    );
  }

  return response;
};
