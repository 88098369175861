import React, { useEffect, useState } from 'react';
import './style.css';
import { ReactComponent as RecordIcon } from 'new-ui/assets/icons/record_icon.svg';
import { ReactComponent as BackwardIcon } from 'new-ui/assets/icons/backward.svg';
import { ReactComponent as ForwardIcon } from 'new-ui/assets/icons/forward.svg';
import Radio from 'new-ui/Components/Form/Radio';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import LottieSuccessFeedback from 'new-ui/assets/lottie/add_treatment.json';
import { getLottie, getOverlay } from 'new-ui/functions';
import { MATCH_FLOWS } from 'new-ui/constants';
import WidgetBtn from '../Button';
import StableConditionFeedback from './StableConditionFeedback';
import ActionListV2 from './ActionListV2';
import WidgetSlider from './WidgetSlider';
import AssistanceButton from '../AssistanceButton';
import { FEED_GA_CATEGORY, FEED_GA_EVENTS, FEED_GA } from '..';

let $loading;

const TreatmentWidget = ({
  data, onTreatmentSelect, onTreatmentAdd, onClickAddExperience, onTreatmentCreate,
}) => {
  // const { hasTreatment } = data;
  const [selectedOption, setSelectedOption] = useState(null);
  const [treatmentStatus, setTreatmentStatus] = useState(null);
  const [selectedTreatmentOption, setSelectedTreatmentOption] = useState(null);
  const [creationStatus, setCreationStatus] = useState(null);

  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatmentViewed, {
      widget: 'treatment',
    });
  }, []);

  function buildTreatment(formData) {
    return {
      drugs_received: formData.selectedDrugs,
      treatment_admin: null,
      treatment_stop_reason: formData.stop_reason || null,
      cycles: Array.isArray(formData.cycles) ? null : formData.cycles,
      taking_state: formData.taking_state || null,
      timing: formData.timing || null,
      // received_treatment: !!treatmentStatus,
      received_treatment: null,
      date_of_adding: Date.now(),
    };
  }

  const { t } = useTranslation();

  const onClickOption = (option) => {
    setSelectedOption(option);
  };

  const handleTreatmentSelect = (option) => {
    onTreatmentSelect(option.value);
    setSelectedTreatmentOption(option.value);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatmentInteracted, {
      widget: 'treatment',
    });
  };

  const showSuccessFeedback = () => {
    $loading = getOverlay();
    const $lottie = getLottie(LottieSuccessFeedback);
    $loading.append($lottie);
    document.body.appendChild($loading);
    $loading.show();
    setTimeout(() => {
      $loading.remove();
    }, 2000);
  };

  const handleDoneClick = async (data) => {
    const payload = {
      ...data,
      received_treatment: !!treatmentStatus,
    };
    console.log('handleDoneClick PAYLOAD ', payload);
    app.setShowPreloader(true);
    const newTreatment = {
      cycles: payload.cycles || null,
      drugs_received: payload.selectedDrugs,
      treatment_stop_reason: payload.stop_reason || null,
      taking_state: payload.taking_state || null,
    };
    const treatments = getExistingTreatments(app.user.condition_profile.treatments, newTreatment);
    treatments.push(newTreatment);
    app.user.condition_profile.treatments = treatments;

    await app.updateUser(app.user);
    await app.getTrials(MATCH_FLOWS.BASIC);
    app.renderPage();
    app.setShowPreloader(false);
    showSuccessFeedback();
    setCreationStatus('completed');
    onTreatmentAdd();
  };

  const handleTreatmentChange = (newValue) => {
    setTreatmentStatus(newValue);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_not_received_clicked);
  };

  const renderComponent = (selectedTreatmentOption) => {
    switch (selectedTreatmentOption) {
      case 'done_for_now':
        return <StableConditionFeedback />;
      case 'received_addiotnal_treatment':
        return setSelectedOption(1);
      default:
        return <ActionListV2 onClickOption={handleTreatmentSelect} />;
    }
  };

  const handleTreatmentFormChange = async (formData) => {
    if (!formData.selectedDrugs.length) {
      return;
    }
    const newTreatment = buildTreatment(formData);
    const treatments = getExistingTreatments(app.user.condition_profile.treatments, newTreatment);
    treatments.push(newTreatment);
    app.user.condition_profile.treatments = treatments;
    setCreationStatus('in_progress');
    await app.updateUser(app.user);
    app.renderPage();
    onTreatmentCreate();
  };

  function getExistingTreatments(treatments, newTreatment) {
    return treatments.filter((treatment) => {
      const sortedDrugs = treatment.drugs_received.sort().join('_');
      return sortedDrugs !== newTreatment.drugs_received.sort().join('_');
    });
  }

  const getDrugName = () => {
    const { treatments } = app.user.condition_profile;
    if (!treatments?.length) return '';
    const drugsReceived = treatments[treatments.length - 1]?.drugs_received || [];
    const drugNames = drugsReceived.map((n) => t(`questionnaire.questions.drugs_received.${n}`));
    return drugNames.join(' + ');
  };

  return (
    <div className={`treatment-widget step-${selectedTreatmentOption}`}>
      <div className="widget-header">
        {creationStatus !== 'in_progress' && <h3 className="sub-title">{t('treatment_widget.warning.action_required')}</h3>}
        {creationStatus === 'in_progress' && <h3 className="sub-title">{t('treatment_widget.warning.impact_treatment')}</h3>}
        {(getDrugName() && creationStatus !== 'in_progress') && (
          <h2 className="title">
            Are you still on&nbsp;
            {getDrugName()}
            ?
          </h2>
        )}
        {(getDrugName() && creationStatus === 'in_progress') && (
          <h2 className="title">
            Complete your treatment update for&nbsp;
            {getDrugName()}
            ?
          </h2>
        )}

      </div>
      <div className="widget-body">
        {!selectedOption && (
          <div className="actions-cont">
            <h2 className="title">{t('treatment_widget.description')}</h2>
            {!app.user.condition_profile.treatments?.length ? (
              <ul className="actions-list">
                  {app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_viewed)}
                <li
                  className="actions-item"
                  onClick={() => {
                    app.sendGoogleAnalyticsEvent(FEED_GA_CATEGORY, FEED_GA_EVENTS.WIDGET_INTERACTED, {
                      widget: 'taking_btn_clicked',
                    });
                    onClickOption('taking');
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_add_clicked);
                  }}
                >
                  <RecordIcon />
                  <span className="label">{t('treatment_widget.options.current_treatment')}</span>
                </li>
                <li
                  className="actions-item"
                  onClick={() => {
                    app.sendGoogleAnalyticsEvent(FEED_GA_CATEGORY, FEED_GA_EVENTS.WIDGET_INTERACTED, {
                      widget: 'last_treatment_btn_clicked',
                    });
                    onClickOption('last');
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_add_last_clicked);
                  }}
                >
                  <BackwardIcon />
                  <span className="label">{t('treatment_widget.options.last_treatment')}</span>
                </li>
                <li
                  className="actions-item"
                  onClick={() =>{
                    onClickOption('first');
                    app.sendGoogleAnalyticsEvent(FEED_GA_CATEGORY, FEED_GA_EVENTS.WIDGET_INTERACTED, {
                      widget: 'first_treatment_btn_clicked',
                    });
                    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_add_first_clicked);
                  }}
                >
                  <ForwardIcon />
                  <span className="label">{t('treatment_widget.options.first_treatment')}</span>
                </li>
              </ul>
            ) : (
              renderComponent(selectedTreatmentOption)
            )}

            {!app.user.condition_profile.treatments?.length && (
              <div className="radios">
                <Radio
                  options={[{ value: 'not_received_treatment', title: t('treatment_widget.options.not_received') }]}
                  value={null}
                  onChange={handleTreatmentChange}
                />
              </div>
            )}
          </div>
        )}
        {selectedOption && (
          <WidgetSlider
            data={data}
            onClickDone={handleDoneClick}
            selectedOption={selectedOption}
            onFormChange={handleTreatmentFormChange}
          />
        )}
      </div>
      <div className={`widget-footer step-${selectedTreatmentOption}`}>
        <WidgetBtn title="Add your experience" onClick={onClickAddExperience} />
        <AssistanceButton title={t('treatment_widget.actions.assistance.button')} onClick={() => {
          app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.treatment_q_need_assistance_clicked);
        }} />
      </div>
    </div>
  );
};

export default TreatmentWidget;
