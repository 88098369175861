import React from 'react';
import './style.css';
import { ReactComponent as MessageIcon } from 'new-ui/assets/icons/message-text.svg';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
// import { useTranslation } from 'utils/modifiedTranslation';

const AssistanceButton = ({title='Need Assistance?', onClick}) => {
    // const { t } = useTranslation();
    return (
        <div className='assistance-btn' onClick={() => {
            app.setPopup(POPUP_TYPES.CHAT);
            onClick();
        }}>
            <MessageIcon />
            <span className='label'>{title}</span>
        </div>
    );
};

export default AssistanceButton;