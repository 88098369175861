export const GA_HCP = {
  category: 'HCP',
  events: {
    TREATMENTS_TAB_CLICKED: 'HCP treatment tab click',
    FINANCE_TAB_CLICKED: 'HCP finance tab click',
    UPLOAD_FILES_CLICKED: 'HCP upload medical files clicked',
    NGS_CLICKED: 'HCP NGS upload clicked',
    PROFILE_UPDATED: 'HCP profile updated',
    NEW_CLICKED: 'HCP new button clicked',
    SIGNUP_POPUP: 'HCP signup popup',
    SIGNUP_CLICKED: 'HCP signup button clicked',
    LOGIN_CLICKED: 'HCP login button clicked',
    LEARN_MORE_CLICKED: 'HCP learn more clicked',
    NGS_SUCCESS: 'HCP NGS success',
    UPLOAD_SUCCESS: 'HCP upload files success',
    FILTER_CHANGED: 'HCP filter changed',
  },
};
