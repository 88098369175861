/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './UploadPopup.css';

import app from 'new-ui/app';
import FilesUploader, { UPLOADER_STATES } from 'new-ui/Components/FilesUploader';
import Button from 'new-ui/Components/Button';
import Vimeo from '@u-wave/react-vimeo';
import { ACCEPTED_FILE_TYPES } from 'new-ui/NGS';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import { uploadFile } from 'new-ui/Pages/Upload/api';
import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import { getDrugName, questionsNormalizer, waitFor } from 'new-ui/functions';
import { UPLOAD_INTAKE_QUESTIONS } from 'new-ui/Components/Intake/questions';
import Intake from 'new-ui/Components/Intake';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import ChatButton from 'new-ui/ChatButton';
import { INDICATIONS, PAGES, POPUP_TYPES, THEMES } from 'new-ui/constants';
import { render } from 'react-dom';
import NextIcon from 'new-ui/assets/icons/next.svg';
import InfinityLoader from 'new-ui/Components/InfinityLoader';
import useRecaptcha from '../../../utils/hooks/useRecaptcha';
import { addUserDocuments } from '../../../modules/userDocuments/api';
import { mock_lung_extraction } from './mock';

export const ACCEPTED_FILE_CONTENT_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'image/jpg',
];

const GA = {
  category: 'Upload',
  events: {
    pageview: 'Upload file landing page viewed',
    browse: 'Clicking on browse',
    dragFile: 'Drag a file',
    sendRecord: 'Clicking on Send record by mail',
    answerManually: 'Clickin on Answer manually',
    emailToLater: 'Upload file clicking continue later',
    emailToLaterForm: 'Continue later form viewed',
    emailToLaterPopup: 'Continue later final popup viewed',
    moreUpload: 'Upload more clicked',
    next: 'Clicking on “Next”',
    startVideo: 'Upload file video started',
    endVideo: 'Upload file video finished',
    fileSuccessfully: 'Upload file extracted successfully',
    fileFailed: 'Upload file extracted failed',
    reviewing: 'When the revireing page is presented',
    confirmaed: 'When confirmaed',
    success: 'signup_success',
    error: 'signup_error',
  },
};

const uploader = {};

let user;
let results;

const getVimeoId = ()=>{
  if (app.isCurrentPage(PAGES.SEARCH)) return UPLOAD_VIMEO_IDS[PAGES.SEARCH];
  return UPLOAD_VIMEO_IDS[PAGES.UPLOAD];
};

export const UPLOAD_VIMEO_IDS = {
  [PAGES.UPLOAD]: '944032597',
  [PAGES.SEARCH]: '995786509',
};

const UploadPopup = (props) => {
  const { t } = useTranslation();

  const {
    onSuccess,
    // onSet,
    onBeforeIntake,
    condition,
  } = props;

  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const [job, setJob] = useState(null);
  const [biomarkers, setBiomarkers] = useState([]);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const [errorMesage, setErrorMessage] = useState(null);
  const [finishUploading, setIsFinishUploading] = useState(false);
  const [drugsRecevedFromJob, setDrugsRecevedFromJob] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const AiAnimate = ({ onSkip, onEnd }) => {
    return (
      <div className="upload-preloader">
        <div className="upload-title">
          {t('ai_profile.analyzing_your_files')}
        </div>
        <div className="upload-preloader-bar upload-popup-preloader-bar">
          <InfinityLoader />
        </div>
        <div>
          <Vimeo
            video={getVimeoId()}
            autoplay
            onEnd={onEnd}
            onPlay={()=>app.sendGoogleAnalyticsEvent(GA.category, GA.events.startVideo, { name: 'Upload file video started' })}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          />
        </div>
        { onSkip ? (
          <div className="skip">
            <Button theme={THEMES.LIGHT} forwardIcon={NextIcon} title={t('general.skip')} action={onSkip} />
          </div>
        ) : null}
        {/* <div className="upload-lottie-holder">
          <Lottie animationData={LottieAnimation} loop />
        </div> */}
      </div>
    );
    // let f = 0;
    // let i;
    // i = setInterval(()=>{
    //   if (f === 0) f = 1;
    //   else f = 0;
    //   const { img } = contentAiAnimate[f];
    //   const txt = contentAiAnimate[f].text;
    //   const $el = document.querySelector('.ai-profile-uploading');
    //   if ($el) {
    //     $el.querySelector('.uploading-animate').innerHTML = '';
    //     const $img = document.createElement('img');
    //     $img.src = img;
    //     $el.querySelector('.uploading-animate').appendChild($img);
    //     $el.querySelector('.text').innerHTML = t(txt);
    //   }
    //   setTimeout(()=>{
    //     clearInterval(i);
    //     i = null;
    //   }, 60000);
    // }, 6000);
    // return (
    //   <div className="uploading ai-profile-uploading">
    //     <div className="uploading-animate">
    //       <img src={contentAiAnimate[0].img} alt="ai" />
    //     </div>
    //     <div className="text">{t(contentAiAnimate[0].text)}</div>
    //   </div>
    // );
  };

  const showSkip = ()=>{
    const $skip = document.querySelector('.upload-preloader .skip');
    if ($skip) {
      $skip.classList.add('visible');
    }
    const $prog = document.querySelector('.upload-preloader .upload-preloader-bar');
    if ($prog) {
      $prog.classList.add('visibility-hidden');
    }
  };

  useEffect(()=>{
    if (finishUploading) {
      if (isPlaying) {
        showSkip();
      } else uploader.setState(UPLOADER_STATES.CUSTOM1);
    }
    // eslint-disable-next-line
  }, [finishUploading]);

  // useEffect(()=>{
  //   if (uploaderState === UPLOADER_STATES.ANALYZING) {
  //     app.setPopup(false);
  //   }
  // }, [uploaderState]);

  useEffect(() => {
    console.log(5);
    const updateUserDocuments = async () => {
      await addUserDocuments({
        jobId: job.id,
        profileId: app.user.personal.profile_id,
        files: job.files,
        source: 'upload',
      });
    };

    if (!!job && !!app.user && !!uploader?.files?.length && !!app.user.personal.profile_id && uploaderState === UPLOADER_STATES.CUSTOM1) {
      console.log(2);
      updateUserDocuments();
    }
  }, [job, uploaderState]);

  useEffect(()=>{
    if (window.__upload_debug) {
      user = mock_lung_extraction;
      setUploaderState(UPLOADER_STATES.CUSTOM1);
    }
  }, []);

  const gotoNextStep = ()=>{
    uploader.setState(UPLOADER_STATES.CUSTOM1);
  };

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return <div className="upload-popup-title">Got a medical record?</div>;
      case UPLOADER_STATES.MULTI:
        return <div className="upload-popup-title">Is that all?</div>;
      case UPLOADER_STATES.UPLOADING:
        return (
          <div className="animate">
            {AiAnimate({
              onSkip: gotoNextStep,
              onEnd: ()=>{
                setIsPlaying(false);
                if (finishUploading) gotoNextStep();
                else {
                  // const $animate = document.querySelector('.animate');
                  // if ($animate) $animate.classList.add('hidden');
                }
              },
            })}
          </div>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            {errorMesage}
          </div>
        );
      case UPLOADER_STATES.ANALYZING:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return null;
      case UPLOADER_STATES.CUSTOM1:
        // here app.user should be avail. after success signup

        const fetch = async ()=>{
          const _condition = user?.personal?.condition;

          window.__uploadUserBefore = JSON.parse(JSON.stringify(user));

          if (condition) {
            if (condition !== _condition) {
              // uploadError();
              // setErrorMessage(t('upload.error_message_support_text'));
              const $el = document.querySelector('.files-uploader');
              render(
                (
                  <>
                    <div className="file-uploader-title">
                      Cannot extract
                      <span role="img" aria-label="smily">😅</span>
                    </div>
                    <div className="file-uploader-text">
                      The indication found in the extracted file was not the same as the one in the current profile
                    </div>
                    <Button
                      title="Close"
                      action={()=>{
                        app.setPopup(false);
                      }}
                    />
                  </>
                ),
                $el,
              );
              return;
            }
          }
          // const condition = UPLOAD_MOCK.indication; // TODO REMOVE
          // app.user = UPLOAD_MOCK.profile;

          // this should come from outside

          const questions = questionsNormalizer(UPLOAD_INTAKE_QUESTIONS, _condition);
          // user.personal.condition = _condition;
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.reviewing, { name: 'Upload file attrubutes reviewing viewed' });

          switch(_condition){
            case INDICATIONS.LUNG:
              questions.push('braf_mutation','egft_mut','kras_mut','erbb2_her2_mut','met_mut')
              break;
            case INDICATIONS.CRC_2:
            case INDICATIONS.CRC:
              questions.push('kras_mut')
              break;
            case INDICATIONS.MELANOMA:
            case INDICATIONS.MELANOMA_2:
              questions.push('braf_mutation')
              break;
            default:
              break;
          }

          const DRUGS_RECEIVED_QID = 'drugs_received';

          questions.unshift(DRUGS_RECEIVED_QID);

          if (onBeforeIntake) await onBeforeIntake(questions, results, user);

          const DashboardButton = () => {
            return (
              <>
                {/* {uploadHeader()} */}
                <div className="upload-intake-parent-button">
                  <Button
                    forwardIcon={ArrowCirlce}
                    action={async () => {
                      app.sendGoogleAnalyticsEvent(GA.category, GA.events.confirmaed, { name: 'Upload file attrubutes confirmed' });
                      if (onSuccess) await onSuccess(user, questions);
                      app.setPopup(false);
                    }}
                    title={t('upload.confirm_and_see_btn_text')}
                  />
                </div>
              </>
            );
          };
          console.log('user before intake', user);
          let appendQuestions = [
            {
              id: DRUGS_RECEIVED_QID,
              section: 'MY_CANCER',
              type: QUESTION_TYPES.DRUGS_RECEIVED,
              valueRenderer: () => {
                if (!drugsRecevedFromJob || !drugsRecevedFromJob?.length) return 'No drugs found';
                return drugsRecevedFromJob.map((drug) => window.t(getDrugName(drug))).join(', ');
              },
            },
          ];
          let expandQuestions = [DRUGS_RECEIVED_QID];
          if (!user.condition_profile.drugs_received) {
            appendQuestions = undefined;
            expandQuestions = undefined;
          }
          const $div = (
            <div>
              <div className="upload-popup-title">{t('upload.popup_title')}</div>
              <div className="upload-intake-block">
                <div className="upload-intake-parent">
                  {/* <DashboardButton /> */}
                  <Intake
                    id="upload"
                    showOnlyAnswered
                    customQuestions={questions}
                    expandQuestions={expandQuestions}
                    appendQuestions={appendQuestions}
                    flatMode
                    onUpdate={(user) => {
                      onSuccess(user);
                      console.log('on intake update user', user);
                    // app.updateUser(user, true);
                    // convert user to search params and set to tempUser
                    //
                    }}
                    user={user}
                  />
                  <ChatButton popupType={POPUP_TYPES.CHAT_UPLOAD} text="Understand your profile" />
                  <DashboardButton />
                </div>
              </div>
            </div>
          );
          waitFor(()=>{
            return document.querySelector('.ai-upload-intake');
          }, ()=>{
            render($div, document.querySelector('.ai-upload-intake'));
          }, ()=>{
            console.log('too long');
          });
        };

        fetch();
        return <div className="ai-upload-intake" />;
    }
  };

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
  };

  const errorFooter = () => (
    <div>
      <Button title="Upload another file" action={back} />
    </div>
  );

  // const successFooter = () => (
  //   <div className="success-footer">
  //     <Button
  //       title="Apply biomarkers and search"
  //       action={() => {
  //         onSuccess(biomarkers);
  //       }}
  //     />
  //   </div>
  // );

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return errorFooter();
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : null;
    }
  };

  const filerenderer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ERROR:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return null;
    }
  };

  const uploadError = () => {
    uploader.setState(UPLOADER_STATES.ERROR);
  };

  const upload = async () => {
    const files = [...uploader.files];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    const filterFilesByFormat = files.every((file) => ACCEPTED_FILE_CONTENT_TYPES.includes(file.type));

    if (!filterFilesByFormat) {
      uploadError();
      setErrorMessage(t('upload.error_message_support_text'));
      return;
    }

    uploadFile(files, getRecaptchaTokenAsync, async (r) => {
      if (r?.status === 'completed') {
        // todo document indication is not same as user indication error
        user = r.profile;
        results = r;
      }
      if (r?.status === 'error' || r?.status === 'file_error') {
        uploadError();
        if (r?.error) {
          setErrorMessage(t(r?.error));
        } else {
          setErrorMessage(null);
        }
        return;
      }
      if (r?.indication) {
        // setIsFinishUploading(true);
      }

      const profile = r?.profile ?? {};
      const conditionProfile = profile?.condition_profile ?? {};
      const biomarkers = conditionProfile?.biomarkers ?? [];
      const drugs = conditionProfile?.drugs_received;

      setJob(r);
      setBiomarkers(biomarkers);
      setDrugsRecevedFromJob(drugs);
      setIsFinishUploading(true);
      user = profile;
      uploader.setFiles(files);
      if (isPlaying) {
        showSkip();
      } else uploader.setState(UPLOADER_STATES.CUSTOM1);
    }, () => {
      uploader.setState(UPLOADER_STATES.ERROR);
    }, undefined, app?.user?.personal?.condition, undefined, true);
  };

  return (
    <div className="upload-popup">
      {recaptcha}
      <FilesUploader
        isUploadPopup
        isMulti
        onstatechange={(state)=>{
          const $img = document.querySelector('.popup-theme-middle .popup-header img');
          if ($img) {
            $img.classList.remove('hidden');
            if (state === UPLOADER_STATES.UPLOADING) {
              $img.classList.add('hidden');
            }
          }

          setUploaderState(state);
        }}
        header={header()}
        footer={footer()}
        filerenderer={filerenderer}
        uploader={uploader}
        fileTypes={ACCEPTED_FILE_TYPES}
        fileTypesError={t('upload.file_type_error_message')}
        onfilesselected={() => {
        }}
        onselect={() => {
          return upload();
        }}
        onFileError={() => {
        }}
      />
    </div>
  );
};

UploadPopup.propTypes = {
  onSuccess: PropTypes.func,
  condition: PropTypes.any,
  onBeforeIntake: PropTypes.func,
};

export default UploadPopup;
