export const ENV_TYPES = {
  PRODUCTION: 'production',
  STAGE: 'stage',
  LOCAL: 'local',
};

export const PAGES = {
  RESULTS: 'RESULTS',
  SUMMARY: 'SUMMARY',
  TRIAL: 'TRIAL',
  TREATMENT: 'TREATMENT',
  PUBLIC_TRIAL: 'PUBLIC_TRIAL',
  PUBLIC_TREATMENT: 'PUBLIC_TREATMENT',
  PUBLIC_TRIAL_WITH_CONDITION: 'PUBLIC_TRIAL_WITH_CONDITION',
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  SIGNUP: 'SIGNUP',
  PLAYGROUND: 'PLAYGROUND',
  SEARCH: 'SEARCH',
  NGS: 'NGS',
  MAINTENANCE: 'MAINTENANCE',
  INTAKE: 'INTAKE',
  FINANCE_INTAKE: 'FINANCE_INTAKE',
  UPLOAD: 'UPLOAD',
  FINANCE: 'FINANCE',
  PUBLIC_FINANCE: 'PUBLIC_FINANCE',
  FEED: 'FEED',
  PATIENTS: 'PATIENTS',
  CHAT: 'CHAT',
};

window.__PAGES = PAGES;

export const ROUTES = {
  [PAGES.RESULTS]: '/dashboard/results',
  [PAGES.SUMMARY]: '/dashboard/summary',
  [PAGES.TRIAL]: '/dashboard/trial',
  [PAGES.TREATMENT]: '/dashboard/treatment',
  [PAGES.PUBLIC_TRIAL]: '/trial',
  [PAGES.PUBLIC_TREATMENT]: '/treatment',
  [PAGES.LOGIN]: '/login',
  [PAGES.FORGOT_PASSWORD]: '/login/forgot-password',
  [PAGES.CREATE_PASSWORD]: '/login/create-password',
  [PAGES.NEW_PASSWORD]: '/login/reset-password',
  [PAGES.SIGNUP]: '/dashboard/signup',
  [PAGES.FINANCE]: '/dashboard/finance',
  [PAGES.FINANCE_INTAKE]: '/finance_intake',
  [PAGES.PATIENTS]: '/dashboard/patients',
  [PAGES.PUBLIC_FINANCE]: '/finance',
  [PAGES.FEED]: '/dashboard/feed',
  [PAGES.PLAYGROUND]: '/playground',
  [PAGES.SEARCH]: '/search',
  [PAGES.NGS]: '/NGS',
  [PAGES.MAINTENANCE]: '/maintenance.html',
  [PAGES.INTAKE]: '/intake',
  [PAGES.UPLOAD]: '/ai_profile',
  [PAGES.CHAT]: '/dashboard/chat',
};

window.__ROUTES = ROUTES;

export const URI = {
  [PAGES.RESULTS]: '/dashboard/results',
  [PAGES.SUMMARY]: '/dashboard/summary',
  [PAGES.TRIAL]: '/dashboard/trial/:nctNumber',
  [PAGES.TREATMENT]: '/dashboard/treatment/:nctNumber',
  [PAGES.PUBLIC_TRIAL]: '/trial/:nctNumber',
  [PAGES.PUBLIC_TREATMENT]: '/treatment/:nctNumber',
  [PAGES.PUBLIC_TRIAL_WITH_CONDITION]: '/trial/:condition/:nctNumber',
  [PAGES.LOGIN]: '/login',
  [PAGES.FORGOT_PASSWORD]: '/login/forgot-password',
  [PAGES.CREATE_PASSWORD]: '/login/create-password',
  [PAGES.NEW_PASSWORD]: '/login/reset-password',
  [PAGES.SIGNUP]: '/dashboard/signup',
  [PAGES.FINANCE]: '/dashboard/finance',
  [PAGES.PATIENTS]: '/dashboard/patients',
  [PAGES.FEED]: '/dashboard/feed',
  [PAGES.FINANCE_INTAKE]: '/finance_intake',
  [PAGES.PUBLIC_FINANCE]: '/finance',
  [PAGES.PLAYGROUND]: '/playground',
  [PAGES.SEARCH]: '/search',
  [PAGES.NGS]: '/NGS',
  [PAGES.MAINTENANCE]: '/maintenance.html',
  [PAGES.INTAKE]: '/intake',
  [PAGES.UPLOAD]: '/ai_profile',
  [PAGES.CHAT]: '/dashboard/chat',

  
};

export const PUBLIC_ROUTES = [
  ROUTES[PAGES.LOGIN],
  ROUTES[PAGES.FORGOT_PASSWORD],
  ROUTES[PAGES.CREATE_PASSWORD],
  ROUTES[PAGES.NEW_PASSWORD],
  ROUTES[PAGES.SIGNUP],
  ROUTES[PAGES.PLAYGROUND],
  ROUTES[PAGES.SEARCH],
  ROUTES[PAGES.PUBLIC_TRIAL],
  ROUTES[PAGES.PUBLIC_TREATMENT],
  ROUTES[PAGES.NGS],
  ROUTES[PAGES.MAINTENANCE],
  ROUTES[PAGES.UPLOAD],
  ROUTES[PAGES.CHAT],
  
];

export const ROUTE_TO_PAGE = {
  [ROUTES.RESULTS]: PAGES.RESULTS,
  [ROUTES.TRIAL]: PAGES.TRIAL,
};

export const MOBILE_WIDTH = 800;
export const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const COLORS = {
  primaryButton: '#FF8C69', // '#0C9BED',
  primaryButtonText: '#fff',
  blue: '#0C9BED',
};

export const THEMES = {
  ORANGE: 'orange',
  ORANGE_FULL: 'orange-full',
  ORANGE_TRANSPARENT: 'orange-transparent',
  LIGHT: 'light',
  SMALL_GREY: 'small-grey',
  PURPLE: 'purple',
  BLACK: 'black',
  GREY: 'grey',
};

export const ACTION_TYPES = {
  init: 'USER_INTENT_FLOW',
  default: 'SHARE_WITH_DOCTOR', // must be static (non-flow)
  USER_INTENT: 'USER_INTENT', // current status
  USER_INTENT_FLOW: 'USER_INTENT_FLOW',
  CONNECT_WITH_ONCOLOGIST: 'CONNECT_WITH_ONCOLOGIST',
  CONSULT_WITH_US: 'CONSULT_WITH_US',
  DOCTOR_FLOW: 'DOCTOR_FLOW',
  SHARE_WITH_DOCTOR: 'SHARE_WITH_DOCTOR',
  SEND_TO_DOCTOR: 'SEND_TO_DOCTOR',
  ADD_DOCTOR: 'ADD_DOCTOR',
  SEARCH_DOCTOR: 'SEARCH_DOCTOR',
  NEXT_SCAN: 'NEXT_SCAN',
  LOGIN_WITH_PHONE: 'LOGIN_WITH_PHONE',
  EDIT_EMAIL: 'EDIT_EMAIL',
  NEXT_APPOINTMENT: 'NEXT_APPOINTMENT',
  TREATMENT_EXPERIENCE: 'TREATMENT_EXPERIENCE',
  SEE_ALL_OPTIONS: 'SEE_ALL_OPTIONS',
  MISSING_DATA: 'MISSING_DATA',
  MISSING_EMAIL: 'MISSING_EMAIL',
  PHONE_LOGIN: 'PHONE_LOGIN',
  EBOOK: 'EBOOK',
  GENERAL: 'GENERAL',
};

export const POPUP_TYPES = {
  CONSULT_WITH_US: 'consult_with_us',
  CONNECT_WITH_ONCOLOGIST: 'connect_with_oncologist',
  SHARE_WITH_DOCTOR: 'share_with_doctor',
  SEND_TO_DOCTOR: 'send_to_doctor',
  ADD_DOCTOR: 'add_doctor',
  SEARCH_DOCTOR: 'search_doctor',
  NEXT_SCAN: 'next_scan',
  CHANGE_LOCATION: 'change_location',
  USER_INTENT: 'user_intent',
  EDIT_EMAIL: 'edit_email',
  EDIT_QUESTION: 'edit_question',
  MEDICAL_PROFILE: 'medical_profile',
  QUESTIONNAIRE: 'questionnaire',
  QUESTIONS: 'questions',
  HAS_ANYTHING_CHANGED: 'has_anything_changed',
  NEXT_APPOINTMENT: 'next_appointment',
  TREATMENT_SURVEY: 'treatment_survey',
  TRAVELING_PREFERENCES: 'traveling_preferences',
  SEE_ALL_OPTIONS: 'see_all_options',
  MISSING_CRITICAL_INFORMATION: 'missing_critical_information',
  DEBUG: 'debug',
  GENERAL: 'general',
  CONTACT_US: 'contact_us',
  PERSONALIZE: 'personalize',
  PREFERENCE_CATEGORY: 'preference_category',
  NGS: 'ngs',
  BIOMARKERS: 'biomarkers',
  PHONE_NUMBER: 'phone_number',
  SIGNUP: 'signup',
  CALLUS: 'call_us',
  UNSUBSCRIBE_FORM: 'unsubscribe_form',
  INTAKE: 'intake',
  NEW_MATCHES: 'new_matches',
  ABOUT_YOU: 'about_you',
  CHAT: 'chat',
  CHAT_UPLOAD: 'chat_upload',
  CHAT_UPLOAD_EXPLAIN: 'chat_upload_explain',
  CHAT_RETURN_USER: 'chat_return_user',
  UPLOAD: 'upload',
  ADD_PATIENT: 'add_patient',
  ADD_CAREGIVER: 'add_caregiver',
  LOGIN: 'login',
  AI_ASSISTANT: 'ai_assistant',
};

export const CHAT_ROLES = {
  ME: 'me',
  JANE: 'Tina (patient navigator)',
  TINA: 'Tina (patient navigator)',
};

export const AUDIT_COMMENTS = {
  DEFAULT: 'user requested to see the full list',
  SEE_ALL_OPTIONS: 'user requested to see the full list after getting no results',
  TRAVELING_PREFERENCES: 'user requested to see the full list after sponsored sites are over 100m',
};

// localStorage.setItem('superuser','true')

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  USER: 'user',
  SUPER_USER: 'superuser',
  JOINED_AT: 'joined_at',
  LANGUAGE: 'lang',
  DOCTOR_ADDED: 'doctor_added',
  DOCTOR_SHARED: 'doctor_shared',
  ACTIONS_SEEN: 'actions_seen',
  MASKED_EMAIL: 'masked_email',
  MASKED_PHONE: 'masked_phone',
  ENC: 'enc',
  LANG: 'lang',
  SHOULD_CREATE_EMAILS_TOKEN: 'shouldCreateEmailsToken',
};

export const CONFIGURATION_UPDATE_KEYS = {
  CONSULT_WITH_US: 'lastConsultWithUs',
  MISSING_CRITICAL_INFORMATION: 'lastMissingCriticalUpdate',
  NEXT_APPOINTMENT: 'lastNextAppointment',
  SHARE_WITH_DOCTOR: 'lastShareWithDoctor',
};

export const MATCH_FLOWS = {
  LATEST: 'latest',
  BASIC: 'basic',
};

export const SORT_TYPES = {
  DEFAULT: 'default',
  RECENT: 'recent',
};

export const USER_INTENT_TYPES = {
  TREATMENT: 'TREATMENT',
  RESEARCH: 'RESEARCH',
  EDUCATE: 'EDUCATE',
  OTHER: 'OTHER',
};

export const QUESTIONS = {
  TREATMENTS: 'treatments',
  BIOMARKERS: 'biomarkers',
};

export const UNSUBSCRIBE_PROPERTIES = {
  EMAIL: 'unsubscribed_from_emails',
  SMS: 'unsubscribed_sms',
};

export const QUESTIONNAIRE_URLS = {
  START: '/questionnaire/disease_status',
  DISEASE_STATUS: '/questionnaire/disease_status',
};

export const INTERCOM_EVENTS = {
  QUICK_SIGNUP: {
    QUICK_SIGNUP: 'quick-signup',
  },
  GENERAL: {
    LAUNCHER_CLICKED_1: 'launcher-clicked-1',
    LAUNCHER_CLICKED_2: 'launcher-clicked-2',
    SIGNUP_15_SECONDS: 'signup-15-seconds',
    SIGNUP_30_SECONDS: 'signup-30-seconds',
  },
  MISSING_CRITICAL_INFORMATION: {
    NEED_HELP: 'missing-critical-information-need-help',
    POPUP_SUCCESS: 'missing-critical-information-succeed',
    POPUP_FAIL: 'missing-critical-information-failed',
    POPUP_COMPLETED: 'missing-critical-information-completed',
  },
  SHARE: {
    TRIALS: 'trials-share',
    TREATMENTS: 'soc-share',
    BLOGPOST: 'blogpost-share',
  },
  SHARE_WITH_DOCTOR: {
    POPUP_SUCCESS: 'share-with-doctor-succeed',
    POPUP_FAIL: 'share-with-doctor-failed',
  },
  CONSULT_WITH_US: {
    POPUP_SUCCESS: 'consult-with-us-succeed',
    POPUP_FAIL: 'consult-with-us-failed',
  },
  NEXT_APPOINTMENT: {
    POPUP_SUCCESS: 'next-appointment-succeed',
    POPUP_FAIL: 'next-appointment-fail',
  },
  NGS: {
    LAUNCHER_CLICKED_1: 'ngs-need-help-1',
    LAUNCHER_CLICKED_2: 'ngs-need-help-2',
    FILE_NOT_READABLE: 'ngs-need-help-not-readable',
    FILE_TOO_LARGE: 'ngs-need-help-file-too-large',
    NO_BIOMARKERS: 'ngs-need-help-no-biomarkers',
    WRONG_FORMAT: 'ngs-need-help-wrong-format',
  },
  NGS_POPUP: {
    FILE_NOT_READABLE: 'ngs-popup-not-readable',
    FILE_TOO_LARGE: 'ngs-popup-file-too-large',
    NO_BIOMARKERS: 'ngs-popup-no-biomarkers',
    WRONG_FORMAT: 'ngs-popup-wrong-format',
  },
  MEDICAL_FILES: {
    UPLOADED: 'medical-files-uploaded',
    SUCCEED: 'medical-files-succeed',
    ERROR: 'medical-files-error',
  },
  UPLOAD_PAGE: {
    USER_SIGNUP: 'user-complete-signup-at-upload-page',
  },
};

export const MAIN_WEBSITE_LINKS = {
  PRIVACY_POLICY: 'https://www.leal.health/privacy-policy',
  TERMS: 'https://www.leal.health/terms',
  ONCOLOGISTS: 'https://leal.health/oncologists',
};

export const PAGES_HEADER_LINKS = {
  [PAGES.SEARCH]: MAIN_WEBSITE_LINKS.ONCOLOGISTS,
};

export const GOOGLE_RECAPTCHA_LINKS = {
  GOOGLE_PRIVACY_POLICY: 'https://policies.google.com/privacy',
  GOOGLE_TERM_OF_SERVICE: 'https://policies.google.com/terms',
};

export const ITEM_TYPES = {
  TRIALS: 'trials',
  TREATMENTS: 'treatments',
};

export const INDICATIONS = {
  MELANOMA: 'melanoma',
  MELANOMA_2: 'melanoma_2',
  CRC: 'crc',
  BC: 'breast_cancer',
  MDS: 'mds',
  MPN: 'mpn',
  BLADDER: 'bladder',
  BLADDER_2: 'bladder_2',
  UTERINE: 'uterine',
  BRAIN: 'brain',
  MM: 'mm',
  LUNG: 'lung',
  BREAST_2: 'breast_2',
  NHL: 'nhl',
  CRC_2: 'crc_2',
  PROSTATE: 'prostate',
  AML: 'aml',
  HNSCC: 'hnscc',
  CLL: 'cll',
  GI_TRACT: 'gi_tract',
  OVARIAN: 'ovarian',
  HCC: 'hcc',
  RCC: 'rcc_kidney',
  MESO: 'mesothelioma',
  GENERAL: 'general',
};

export const DEFAULT_INDICATION = INDICATIONS.BREAST_2;

export const OTHER_INDICATION = 'other_cancer';

export const CALL_SUPPORTED_INDICATIONS = {
  [INDICATIONS.LUNG]: {
    name: 'Tina',
    tel: '+1 513-409-0629',
  },
  [INDICATIONS.HCC]: {
    name: 'Tina',
    tel: '+1 513-409-0629',
  },
  [INDICATIONS.BREAST_2]: {
    name: 'Youvona',
    tel: '+1 512-387-0450',
  },
  [INDICATIONS.OVARIAN]: {
    name: 'Youvona',
    tel: '+1 512-387-0450',
  },
  [INDICATIONS.UTERINE]: {
    name: 'Youvona',
    tel: '+1 512-387-0450',
  },
  [INDICATIONS.CRC]: {
    name: 'Heather',
    tel: '+1 201-782-6721',
  },
  [INDICATIONS.CRC_2]: {
    name: 'Heather',
    tel: '+1 201-782-6721',
  },
  [INDICATIONS.HNSCC]: {
    name: 'Heather',
    tel: '+1 201-782-6721',
  },
  [INDICATIONS.BRAIN]: {
    name: 'Heather',
    tel: '+1 201-782-6721',
  },
  [INDICATIONS.PROSTATE]: {
    name: 'Kimberly',
    tel: '+1 317-721-1075',
  },
  [INDICATIONS.BLADDER_2]: {
    name: 'Kimberly',
    tel: '+1 317-721-1075',
  },
  [INDICATIONS.RCC]: {
    name: 'Kimberly',
    tel: '+1 317-721-1075',
  },
  [INDICATIONS.MELANOMA_2]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.GI_TRACT]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.MPN]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.NHL]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.AML]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.MM]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
  [INDICATIONS.MDS]: {
    name: 'Kristine',
    tel: '+1 973-709-8208',
  },
};

export const SUBTYPE_FIELD_BY_CONDITION = {
  [INDICATIONS.MM]: 'type_of_mm',
  [INDICATIONS.MPN]: 'type_of_mpn',
  [INDICATIONS.AML]: 'type_of_aml',
  [INDICATIONS.CLL]: 'type_of_disease',
  [INDICATIONS.CRC]: 'type_of_crc',
  [INDICATIONS.CRC_2]: 'type_of_crc',
  [INDICATIONS.GI_TRACT]: 'type_of_esophageal_gej',
  [INDICATIONS.HCC]: 'type_of_liver',
  [INDICATIONS.HNSCC]: 'type_of_hnscc',
  [INDICATIONS.LUNG]: 'type_of_lung_cancer',
  [INDICATIONS.MDS]: 'type_of_mds',
  [INDICATIONS.MELANOMA]: 'melanoma_subtype',
  [INDICATIONS.MELANOMA_2]: 'melanoma_subtype',
  [INDICATIONS.NHL]: 'type_of_lymphoma',
  [INDICATIONS.OVARIAN]: 'type_of_ovarian_cancer',
  [INDICATIONS.PROSTATE]: 'type_of_pc',
  [INDICATIONS.UTERINE]: 'type_of_uterine_cancer',
  [INDICATIONS.BLADDER]: 'type_of_bladder_cancer',
  [INDICATIONS.BLADDER_2]: 'type_of_bladder_cancer',
  [INDICATIONS.BRAIN]: 'brain_cancer_type',
  [INDICATIONS.BREAST_2]: 'type_of_bc',
};

export const SURVEYS = {
  TREATMENT_EXPERIENCE: 'treatmentExperience',
};

export const MARKETING_SITE = 'https://www.leal.health';

export const MARKETING_SITE_LINKS = {
  ONCOLOGISTS: `${MARKETING_SITE}/oncologists`,
  PATIENTS: `${MARKETING_SITE}`,
  PHARMA: `${MARKETING_SITE}/pharma`,
  PUBLICATIONS: `${MARKETING_SITE}/publications-and-articles`,
};

export const NGS_HEADER_LINKS = {
  ABOUT: `${MARKETING_SITE}/about`,
  PUBLICATIONS: `${MARKETING_SITE}/publications-and-articles`,
  PATIENTS: MARKETING_SITE,
  PHARMA: `${MARKETING_SITE}/pharma`,
  PHARMAPHISICIAN: `${MARKETING_SITE}/community-patients/physician/page/1`,
  FAQ: `${MARKETING_SITE}/faq`,
  QUESTIONS_TO_ASK: `${MARKETING_SITE}/questions-to-ask`,
  PATIENT_SUPPORT_RESOURCES: `${MARKETING_SITE}/patient-support-resources`,
};

const PREFERENCE_PREFERRED_THERAPHY = 'preferred_intervention';
const PREFERENCE_PREFERRED_IN_SOME_CASES = 'preferred_in_certain_circumstances';
const PREFERENCE_OTHER_RECOMENDED_THERAPY = 'other_recommended_intervention';
const PREFERENCE_USEFUL_IN_CERTAIN_CIRCUMSTANCES = 'useful_in_certain_circumstances';
const PREFERENCE_SUPPORTIVE = 'supportive_care';

export const POPUP_PREFERENCES = [
  PREFERENCE_PREFERRED_THERAPHY,
  PREFERENCE_PREFERRED_IN_SOME_CASES,
  PREFERENCE_OTHER_RECOMENDED_THERAPY,
  PREFERENCE_USEFUL_IN_CERTAIN_CIRCUMSTANCES,
  PREFERENCE_SUPPORTIVE,
];

export const PREFERENCE_KEYS = {
  other_recommended_intervention: PREFERENCE_OTHER_RECOMENDED_THERAPY,
  other_within_other_recommended: PREFERENCE_OTHER_RECOMENDED_THERAPY,
  preferred_intervention: PREFERENCE_PREFERRED_THERAPHY,
  preferred_in_certain_circumstances: PREFERENCE_PREFERRED_IN_SOME_CASES, //
  preferred_within_other_recommended: PREFERENCE_OTHER_RECOMENDED_THERAPY,
  supportive_care: PREFERENCE_SUPPORTIVE,
  useful_in_certain_circumstances: PREFERENCE_USEFUL_IN_CERTAIN_CIRCUMSTANCES,
  useful_within_other_recommended: PREFERENCE_OTHER_RECOMENDED_THERAPY,
};

export const CRM_EVENTS = {
  CALL_US: 'CALL_US',
  MISSED_CALL: 'MISSED_CALL',
  TRIED_TO_SCHEDULE_A_CALL: 'TRIED_TO_SCHEDULE_A_CALL',
};

export const ALL_INDICATIONS = Object.values(INDICATIONS);

window.indications = ALL_INDICATIONS;

export const INTAKE_INDICATIONS = ALL_INDICATIONS;

// those indications will be redirected to intake page on signup success
export const INTAKE_REDIRECT_INDICATIONS = ALL_INDICATIONS;

// export const INTAKE_INDICATIONS = [];

window.__intakeIndications = INTAKE_INDICATIONS;

export const CHAT_INDICATIONS = [
  INDICATIONS.AML,
  INDICATIONS.BLADDER_2,
  INDICATIONS.BRAIN,
  INDICATIONS.BREAST_2,
  INDICATIONS.CLL,
  INDICATIONS.CRC_2,
  INDICATIONS.GI_TRACT,
  INDICATIONS.HCC,
  INDICATIONS.HNSCC,
  INDICATIONS.LUNG,
  INDICATIONS.MDS,
  INDICATIONS.MELANOMA_2,
  INDICATIONS.MESO,
  INDICATIONS.MM,
  INDICATIONS.MPN,
  INDICATIONS.NHL,
  INDICATIONS.OVARIAN,
  INDICATIONS.PROSTATE,
  INDICATIONS.PROSTATE,
  INDICATIONS.RCC,
  INDICATIONS.UTERINE,
];

export const TREATMENT_GROUPS = [
  'Radiation',
  'Imaging',
  'Medical Device',
  'Targeted therapy',
  'Diagnostics',
  'Cell Therapy',
  'Immunotherapy',
  'Gene Therapy',
  'Chemotherapy',
  'Vaccine',
  'Hormonal Therapy',
  'Oncolytic Virus Therapy',
];
