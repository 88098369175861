import React, { useState, useEffect } from 'react';
import app from 'new-ui/app';
import { useParams } from 'react-router-dom';
import { getTrialByNCT } from 'new-ui/api';
import { urlToNct } from 'new-ui/functions';
import PointsToDiscussWithOncologist from 'new-ui/Widgets/PointsToDiscuss';
import PatientsLikeYou from 'new-ui/Widgets/PatientsLikeYou';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TreatmentItem from './Item';
import InfoNote from 'new-ui/Components/InfoNote';
import { useTranslation } from 'react-i18next';
import { publicItemHeader } from 'new-ui/Trial/PublicItemHeader';
// import { t } from 'utils/modifiedTranslation';
import BackButton from 'new-ui/Components/BackButton';

export const GA_HCP = {
  category: 'HCP',
  events: {
    SOC_VIEWED: 'hcp soc viewed',
  },
};

const PublicTreatment = () => {
  const { t } = useTranslation();
  const { nctNumber } = useParams();
  const history = useHistory();
  const [treatment, setTreatment] = useState([...(app.treatments || [])].find((i) => i.nct_number === urlToNct(nctNumber)));
  const [header, setHeader] = useState(null);

  window.setEligibilityHeader = setHeader;
  const isSearchPage = history.location.pathname.includes('treatment');
  app.treatment = treatment; // debugging purposes

  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SOC_VIEWED);
    document.body.classList.add('public-treatment');
    document.body.classList.add('hide-ineligible-header');
  }, []);

  useEffect(()=>{
    publicItemHeader({
      setHeader,
      item: treatment,
    });
    return ()=>{
      app.setHeader(null);
      const $b = document.querySelector('.eligibility-header-back');
      if ($b?.parentElement) $b.parentElement.removeChild($b);
      document.body.classList.remove('eligibility-body');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const usersCountry = app.user?.info?.country?.location;
      const r = await getTrialByNCT({ nctNumber, usersCountry });
      setTreatment(r);
    };
    if (!treatment || !treatment?.points_to_discuss_with_your_oncologist) fetch();
  }, [nctNumber, treatment]);

  const getInfoBoxTranslation = (treatment) => {
    const categoryKey = treatment?.treatment_category_key?.[0] || null;
    const trans = `match.list.treatment.category.description.${categoryKey}.${treatment.condition}`;
    const translated = t(trans);
    if (trans === translated) return '';
    return translated;
  };

  return (
    treatment ? (
      <div className={isSearchPage ? 'treatment isSearchPage' : 'treatment '}>
        {
          header || null
        }

        <div className="wrapper">
          <br/>
          <BackButton />
          <TreatmentItem
            isFull
            item={treatment}
            type="list"
            friendlyTitle={treatment.title ?? null}
          />
        
          {getInfoBoxTranslation(treatment) && (
            <InfoNote
              info={getInfoBoxTranslation(treatment)}
              theme={'theme_2'}
            />
          )}

          <div className="height-20" />
          <div className="height-20" />
          <PatientsLikeYou treatment={treatment} />
          {
            treatment.points_to_discuss_with_your_oncologist ? <PointsToDiscussWithOncologist isPublic treatment={treatment} /> : null
          }

          {/* {renderActions('mobile')} */}
          <div className="height-20" />
          <div className="height-20" />
        </div>
        {/* {renderActions('desktop')} */}
      </div>
    ) : null
  );
};

export default PublicTreatment;
